<template>
    <v-container>
        <!-- input -->
        <v-row class="my-1">
            <v-col cols="12" md="4" class="py-0 my-0">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            class="my-text-style"
                            v-model="date"
                            label="dari tanggal"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(date)"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="4" class="py-0 my-0">
                <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="date2"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            class="my-text-style"
                            v-model="date2"
                            label="sampai tanggal"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="date2" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu2 = false">
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menu2.save(date2)"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="4" class="py-0 my-0">
                <!-- tombol proses -->
                <v-btn
                    elevation="0"
                    class="mb-5"
                    :disabled="isLogging"
                    :loading="isLogging"
                    @click="loadBukuBesar"
                    >{{ isLogging ? "Logging..." : "Proses" }}</v-btn
                >
                <!-- end tombol proses -->
            </v-col>
        </v-row>
        <!-- end input -->

        <v-row>
            <!-- table akun-->
            <v-col cols="12" md="4" sm="6">
                <p>Pilih kode akun yang akan diproses</p>
                <ejs-grid
                    ref="grid"
                    height="300"
                    :dataSource="akuns"
                    :allowFiltering="true"
                    :selectionSettings="selectionOption"
                >
                    <e-columns>
                        <e-column type="checkbox" width="50"></e-column>
                        <e-column
                            field="kode"
                            headerText="Kode"
                            textAlign="center"
                            width="120"
                        ></e-column>
                        <e-column
                            field="nm_rek"
                            headerText="Nama Rekening"
                            textAlign="left"
                            width="200"
                        ></e-column>
                    </e-columns>
                </ejs-grid>
            </v-col>
            <!-- end table akun -->

            <!-- table bukubesar -->
            <v-col cols="12" md="8" sm="6">
                <p>Buku Besar</p>
                <ejs-grid
                    ref="grid2"
                    height="300"
                    :dataSource="bukubesar"
                    :allowTextWrap="true"
                    :allowGrouping="true"
                    :allowExcelExport="true"
                    :toolbar="toolbarOptions"
                    :toolbarClick="gridExport"
                    :groupSettings="groupOptions"
                >
                    <e-columns>
                        <e-column
                            field="kode"
                            headerText="Kode"
                            textAlign="center"
                            width="120"
                        ></e-column>
                        <e-column
                            field="tgl"
                            headerText="Tanggal"
                            textAlign="center"
                            width="120"
                        ></e-column>
                        <e-column
                            field="no_bukti"
                            headerText="No.Bukti"
                            textAlign="center"
                            width="120"
                        ></e-column>
                        <e-column
                            field="ket"
                            headerText="Keterangan"
                            textAlign="left"
                            width="250"
                        ></e-column>
                        <e-column
                            field="jml_debet"
                            headerText="Debet"
                            textAlign="right"
                            width="150"
                        ></e-column>
                        <e-column
                            field="jml_kredit"
                            headerText="Kredit"
                            textAlign="right"
                            width="150"
                        ></e-column>
                        <e-column
                            field="saldo"
                            headerText="Saldo"
                            textAlign="right"
                            width="150"
                        ></e-column>
                    </e-columns>
                    <e-aggregates>
                        <e-aggregate>
                            <e-columns>
                                <!-- utk sum footer -->
                                <e-column
                                    type="Sum"
                                    field="jml_debet"
                                    :footerTemplate="footerSum"
                                ></e-column>
                                <e-column
                                    type="Sum"
                                    field="jml_kredit"
                                    :footerTemplate="footerSum"
                                ></e-column>
                                <!-- end sum footer -->

                                <!-- sum per group -->
                                <e-column
                                    type="Sum"
                                    field="jml_debet"
                                    :groupFooterTemplate="groupFooterSum"
                                ></e-column>
                                <e-column
                                    type="Sum"
                                    field="jml_kredit"
                                    :groupFooterTemplate="groupFooterSum"
                                ></e-column>
                                <!-- end sum per group -->
                            </e-columns>
                        </e-aggregate>
                    </e-aggregates>
                </ejs-grid>
            </v-col>
            <!-- end bukubesar -->
        </v-row>
    </v-container>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import {
    GridPlugin,
    Group,
    Aggregate,
    Toolbar,
    PdfExport,
    ExcelExport,
    Filter,
} from "@syncfusion/ej2-vue-grids";

Vue.use(GridPlugin);

export default {
    data() {
        return {
            titleNavbar: this.$emit("titleNavbar", "Laporan Buku Besar"),
            idperus: JSON.parse(localStorage.getItem("localDept")).idPerus,
            nmperus: JSON.parse(localStorage.getItem("localDept")).nmPerus,
            userid: JSON.parse(localStorage.getItem("user")).id,
            search: "",
            akuns: [],
            date: new Date().toISOString().substr(0, 10),
            date2: new Date().toISOString().substr(0, 10),
            menu: false,
            menu2: false,
            isLogging: false,
            selectionOption: {
                type: "Multiple",
                checkboxMode: "ResetOnRowClick",
            },
            selected: [],
            bukubesar: [],
            tampungBukuBesar: [],
            toolbarOptions: ["ExcelExport"],
            groupOptions: {
                columns: ["kode"],
                showDropArea: false,
            },
            footerSum: function () {
                return {
                    template: Vue.component("Sum", {
                        template: `<span>Total : {{data.Sum}} </span>`,
                        data() {
                            return {
                                data: {},
                            };
                        },
                    }),
                };
            },
            groupFooterSum: function () {
                return {
                    template: Vue.component("Sum", {
                        template: `<span> {{data.Sum}} </span>`,
                        data() {
                            return {
                                data: {},
                            };
                        },
                    }),
                };
            },
        };
    },

    mounted() {
        this.loadAkun();
    },

    methods: {
        loadAkun: function () {
            let mytoken = localStorage.getItem("token");
            const headers = {
                // "x-csrf-token": $('meta[name="csrf-token"]').attr("content"),
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };

            axios
                .get("/api/jurnal/getAkun", { headers })
                .then((res) => {
                    this.akuns = res.data;
                    // console.log(this.akuns);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async loadBukuBesar() {
            this.isLogging = true;
            this.bukubesar = [];

            this.selected = [];
            this.selected = this.$refs.grid.getSelectedRecords();

            let mytoken = localStorage.getItem("token");
            const headers = {
                // "x-csrf-token": $('meta[name="csrf-token"]').attr("content"),
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };

            let panjangData = 0;
            this.selected.forEach(async (e) => {
                // console.log(e.kode);
                let req = {
                    id_perus: this.idperus,
                    dari_tgl: this.date,
                    sampai_tgl: this.date2,
                    kode: e.kode,
                };

                let a = await axios.post("/api/jurnal/bukubesar", req, {
                    headers,
                });
                try {
                    // this.bukubesar = a.data.data;

                    await Promise.all(
                        a.data.data.map(async (el) => {
                            const x = await el;
                            this.tampungBukuBesar.push(x);
                            // console.log(this.tampungBukuBesar);
                            this.isLogging = true;
                        })
                    );
                    panjangData += 1;
                    // console.log("panjang loop :" + panjangData);
                    // console.log("panjang selected :" + this.selected.length);

                    if (panjangData === this.selected.length) {
                        this.bukubesar = this.tampungBukuBesar;
                        this.isLogging = false;
                        console.log("A");
                    }
                } catch (err) {
                    console.log(err);
                }
            });

            this.tampungBukuBesar = [];

            //utk refresh grid buku besar
            // this.$refs.grid2.ej2Instances.dataSource = this.bukubesar;
        },
        loadBukuBesarX() {
            // ambil data yang terselected

            // this.isLogging = true;
            this.bukubesar = [];

            this.selected = [];
            this.selected = this.$refs.grid.getSelectedRecords();

            let mytoken = localStorage.getItem("token");
            const headers = {
                // "x-csrf-token": $('meta[name="csrf-token"]').attr("content"),
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };

            this.selected.forEach((e) => {
                // console.log("buka : " + e.kode);

                let req = {
                    id_perus: this.idperus,
                    dari_tgl: this.date,
                    sampai_tgl: this.date2,
                    kode: e.kode,
                };

                axios
                    .post("/api/jurnal/bukubesar", req, { headers })
                    .then((res) => {
                        for (var i in res.data.data) {
                            this.tampungBukuBesar.push(res.data.data[i]);
                        }
                        // console.log(this.tampungBukuBesar);
                        // console.log("-------------");

                        this.isLogging = false;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.isLogging = false;
                    });
            });
            console.log(this.tampungBukuBesar);

            // transfer tampungBukubesar ke bukubesar;
            // for (var i in this.tampungBukuBesar) {
            //     this.bukubesar.push(this.tampungBukuBesar[i]);
            // }
            // console.log(this.bukubesar);
            this.tampungBukuBesar = [];
        },

        gridExport(args) {
            var gridInst = this.$refs.grid2;
            if (gridInst) {
                if (args.item.id.includes("pdfexport")) {
                    // gridInst.getColumns()[0].visible = false;
                    gridInst.showSpinner();
                    gridInst.pdfExport({
                        fileName: "jurnal-transaksi.pdf",
                        pageOrientation: "Landscape",
                        pageSize: "Letter",
                        theme: {
                            record: {
                                fontSize: 9,
                            },
                        },
                        header: {
                            fromTop: 0,
                            height: 130,
                            contents: [
                                {
                                    type: "Text",
                                    value: this.nmperus,
                                    position: { x: 0, y: 0 },
                                    style: { fontSize: 20 },
                                },
                                {
                                    type: "Text",
                                    value: "Laporan Jurnal Transaksi",
                                    position: { x: 0, y: 50 },
                                    style: { fontSize: 16 },
                                },
                                {
                                    type: "Text",
                                    value:
                                        "Tanggal " +
                                        this.date +
                                        " s/d " +
                                        this.date2,
                                    position: { x: 0, y: 70 },
                                    style: { fontSize: 16 },
                                },
                            ],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents: [
                                {
                                    type: "Text",
                                    value: "Footer",
                                    position: { x: 0, y: 0 },
                                    style: { fontSize: 20 },
                                },
                                {
                                    type: "PageNumber",
                                    pageNumberType: "Number",
                                    format: "Page {$current} of {$total}", //optional
                                    position: { x: 250, y: 0 },
                                    style: {
                                        // textBrushColor: "#ffff80",
                                        fontSize: 10,
                                        hAlign: "center",
                                        vAlign: "Right",
                                    },
                                },
                            ],
                        },
                    });
                } else if (args.item.id.includes("excelexport")) {
                    console.log("export excel di klik");
                    // gridInst.showSpinner();
                    gridInst.excelExport({
                        fileName: "buku besar.xlsx",
                        theme: {
                            record: {
                                fontSize: 12,
                            },
                        },
                        header: {
                            headerRows: 5,
                            rows: [
                                {
                                    cells: [
                                        {
                                            colSpan: 7,
                                            value: this.nmperus,
                                            style: {
                                                fontSize: 14,
                                                // hAlign: "Center",
                                                bold: true,
                                            },
                                        },
                                    ],
                                },
                                { cells: [{ value: "" }] },
                                {
                                    cells: [
                                        {
                                            colSpan: 7,
                                            value: "Laporan Buku Besar",
                                            style: {
                                                fontSize: 12,
                                                hAlign: "Right",
                                                // bold: true,
                                            },
                                        },
                                    ],
                                },
                                {
                                    cells: [
                                        {
                                            colSpan: 7,
                                            value:
                                                "Tanggal " +
                                                this.date +
                                                " s/d " +
                                                this.date2,
                                            style: {
                                                fontSize: 12,
                                                hAlign: "Right",
                                                // bold: true,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    });
                }
            }
        },
    },

    provide: {
        grid: [Group, Aggregate, Toolbar, PdfExport, ExcelExport, Filter],
    },
};
</script>
