<template>
    <v-container>
        <!-- table -->
        <ejs-grid
            ref="grid"
            height="300px"
            :dataSource="jurnals"
            :allowPaging="false"
            :pageSettings="pageSettings"
            :selectionSettings="selection"
            :toolbar="toolbarOptions"
            :toolbarClick="clickHandler"
            :allowTextWrap="true"
            :allowRowDragAndDrop="allowDrag"
            :allowFiltering="true"
            :filterSettings="filterSettings"
            v-loading="screenLoading"
            style="width: 100%"
        >
            <e-columns>
                <e-column
                    field="tgl"
                    headerText="Tanggal"
                    width="120"
                    format="dMy"
                    textAlign="Center"
                ></e-column>
                <e-column
                    field="no_bukti"
                    headerText="No. Bukti"
                    width="120"
                    textAlign="Center"
                ></e-column>
                <e-column
                    field="kode"
                    headerText="Kode"
                    width="120"
                    textAlign="Center"
                ></e-column>
                <e-column
                    field="ket"
                    headerText="Keterangan"
                    width="150"
                ></e-column>
                <e-column
                    field="jml_debet"
                    headerText="Debet"
                    width="130"
                    format="N2"
                    textAlign="Right"
                ></e-column>
                <e-column
                    field="jml_kredit"
                    headerText="Kredit"
                    width="130"
                    format="N2"
                    textAlign="Right"
                ></e-column>
            </e-columns>

            <e-aggregates>
                <e-aggregate>
                    <e-columns>
                        <!-- utk sum all -->
                        <e-column
                            type="Sum"
                            format="N2"
                            field="jml_debet"
                            :footerTemplate="footerSum"
                        ></e-column>
                        <e-column
                            type="Sum"
                            format="N2"
                            field="jml_kredit"
                            :footerTemplate="footerSum"
                        ></e-column>
                        <!-- end sum all -->
                    </e-columns>
                </e-aggregate>
            </e-aggregates>
        </ejs-grid>
        <!-- end table -->

        <!-- dialog add -->
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="700">
                <v-card>
                    <v-card-title class="text-h5 mb-5">
                        Input Jurnal
                    </v-card-title>
                    <v-card-text>
                        <div>
                            <el-alert
                                title="Data belum lengkap, Kode & Jumlah debet atau kredit"
                                type="warning"
                                effect="light"
                                :closable="false"
                                show-icon
                                v-if="msgWarning"
                            >
                            </el-alert>
                            <el-form
                                size="small"
                                :model="ruleForm"
                                :rules="rules"
                                ref="ruleForm"
                                label-width="150px"
                            >
                                <!-- utk id -->
                                <el-input
                                    v-model="ruleForm.id"
                                    type="hidden"
                                ></el-input>

                                <!-- tanggal -->
                                <el-form-item label="Tanggal : " prop="tgl">
                                    <el-input
                                        ref="tgl"
                                        v-model="ruleForm.tgl"
                                        :disabled="true"
                                    ></el-input>
                                </el-form-item>

                                <!-- no bukti -->
                                <el-form-item
                                    label="No. Bukti : "
                                    prop="nobukti"
                                >
                                    <el-input
                                        ref="nobukti"
                                        v-model="ruleForm.nobukti"
                                        v-if="loading"
                                    ></el-input>
                                    <v-progress-circular
                                        :width="1"
                                        :size="20"
                                        color="green"
                                        indeterminate
                                        v-if="loading == false"
                                    ></v-progress-circular>
                                </el-form-item>

                                <!-- kode debet -->
                                <el-form-item label="Kode Debet : ">
                                    <v-row>
                                        <v-col cols="12" md="8" lg="8">
                                            <v-autocomplete
                                                dense
                                                class="my-text-style"
                                                hide-details="auto"
                                                clearable
                                                clear-icon="mdi-close-circle"
                                                :items="akuns"
                                                item-text="akunConcat"
                                                item-value="kode"
                                                v-model="kdDebet"
                                            ></v-autocomplete>
                                        </v-col>

                                        <v-col cols="12" md="4" lg="4">
                                            <v-text-field
                                                dense
                                                autocomplete="off"
                                                class="my-text-style"
                                                hide-details="auto"
                                                type="number"
                                                clear-icon="mdi-close-circle"
                                                label="Jumlah Debet"
                                                v-model="ruleForm.jml_debet"
                                                :hint="
                                                    formatNumber(
                                                        ruleForm.jml_debet
                                                    )
                                                "
                                                persistent-hint
                                                required
                                            />
                                        </v-col>
                                    </v-row>
                                </el-form-item>
                                <!-- end kode Debet -->

                                <!-- kode kredit -->
                                <el-form-item label="Kode Kredit : ">
                                    <v-row>
                                        <v-col cols="12" md="8" lg="8">
                                            <v-autocomplete
                                                dense
                                                class="my-text-style"
                                                hide-details="auto"
                                                clearable
                                                clear-icon="mdi-close-circle"
                                                :items="akuns"
                                                item-text="akunConcat"
                                                item-value="kode"
                                                v-model="kdKredit"
                                            ></v-autocomplete>
                                        </v-col>

                                        <v-col cols="12" md="4" lg="4">
                                            <v-text-field
                                                dense
                                                autocomplete="off"
                                                class="my-text-style"
                                                hide-details="auto"
                                                type="number"
                                                clear-icon="mdi-close-circle"
                                                label="Jumlah Kredit"
                                                v-model="ruleForm.jml_kredit"
                                                :hint="
                                                    formatNumber(
                                                        ruleForm.jml_kredit
                                                    )
                                                "
                                                persistent-hint
                                                required
                                            />
                                        </v-col>
                                    </v-row>
                                </el-form-item>
                                <!-- end kode Debet -->

                                <!-- ket -->
                                <el-form-item label="Keterangan : " prop="ket">
                                    <el-input
                                        ref="ket"
                                        type="textarea"
                                        :rows="2"
                                        v-model="ruleForm.ket"
                                    >
                                    </el-input>
                                </el-form-item>
                                <!-- end ket -->

                                <!-- btn -->
                                <el-form-item class="mb-1">
                                    <el-button
                                        :loading="loadingBtn"
                                        type="primary"
                                        @click="submitForm('ruleForm')"
                                        >{{
                                            loadingBtn ? "Loading" : "Simpan"
                                        }}</el-button
                                    >
                                    <el-button @click="resetForm('ruleForm')"
                                        >Cancel</el-button
                                    >
                                </el-form-item>
                                <!-- end btn -->
                            </el-form>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- end dialog add -->

        <!-- dialog update -->
        <v-row justify="center">
            <v-dialog v-model="dialogUpdate" persistent max-width="700">
                <v-card>
                    <v-card-title class="text-h5 mb-5">
                        Update Jurnal
                    </v-card-title>
                    <v-card-text>
                        <div>
                            <el-alert
                                title="Data belum lengkap, Kode & Jumlah debet atau kredit"
                                type="warning"
                                effect="light"
                                :closable="false"
                                show-icon
                                v-if="msgWarning"
                            >
                            </el-alert>
                            <el-form
                                size="small"
                                :model="ruleFormUpdate"
                                :rules="rules"
                                ref="ruleFormUpdate"
                                label-width="150px"
                            >
                                <!-- utk id -->
                                <el-input
                                    v-model="ruleFormUpdate.id"
                                    type="hidden"
                                ></el-input>

                                <!-- tanggal -->
                                <el-form-item label="Tanggal : " prop="tgl">
                                    <el-input
                                        ref="tgl"
                                        v-model="ruleFormUpdate.tgl"
                                        :disabled="false"
                                    ></el-input>
                                </el-form-item>

                                <!-- no bukti -->
                                <el-form-item
                                    label="No. Bukti : "
                                    prop="nobukti"
                                >
                                    <el-input
                                        ref="nobukti"
                                        v-model="ruleFormUpdate.nobukti"
                                    ></el-input>
                                </el-form-item>

                                <!-- kode debet -->
                                <el-form-item label="Kode Debet : ">
                                    <v-row>
                                        <v-col cols="12" md="8" lg="8">
                                            <v-autocomplete
                                                dense
                                                class="my-text-style"
                                                hide-details="auto"
                                                clearable
                                                clear-icon="mdi-close-circle"
                                                :items="akuns"
                                                item-text="akunConcat"
                                                item-value="kode"
                                                v-model="kdDebetUpdate"
                                            ></v-autocomplete>
                                        </v-col>

                                        <v-col cols="12" md="4" lg="4">
                                            <v-text-field
                                                dense
                                                autocomplete="off"
                                                class="my-text-style"
                                                hide-details="auto"
                                                type="number"
                                                clear-icon="mdi-close-circle"
                                                label="Jumlah Debet"
                                                v-model="
                                                    ruleFormUpdate.jml_debet
                                                "
                                                :hint="
                                                    formatNumber(
                                                        ruleFormUpdate.jml_debet
                                                    )
                                                "
                                                persistent-hint
                                                required
                                            />
                                        </v-col>
                                    </v-row>
                                </el-form-item>
                                <!-- end kode Debet -->

                                <!-- kode kredit -->
                                <el-form-item label="Kode Kredit : ">
                                    <v-row>
                                        <v-col cols="12" md="8" lg="8">
                                            <v-autocomplete
                                                dense
                                                class="my-text-style"
                                                hide-details="auto"
                                                clearable
                                                clear-icon="mdi-close-circle"
                                                :items="akuns"
                                                item-text="akunConcat"
                                                item-value="kode"
                                                v-model="kdKreditUpdate"
                                            ></v-autocomplete>
                                        </v-col>

                                        <v-col cols="12" md="4" lg="4">
                                            <v-text-field
                                                dense
                                                autocomplete="off"
                                                class="my-text-style"
                                                hide-details="auto"
                                                type="number"
                                                clear-icon="mdi-close-circle"
                                                label="Jumlah Kredit"
                                                v-model="
                                                    ruleFormUpdate.jml_kredit
                                                "
                                                :hint="
                                                    formatNumber(
                                                        ruleFormUpdate.jml_kredit
                                                    )
                                                "
                                                persistent-hint
                                                required
                                            />
                                        </v-col>
                                    </v-row>
                                </el-form-item>
                                <!-- end kode Kredit -->

                                <!-- ket -->
                                <el-form-item label="Keterangan : " prop="ket">
                                    <el-input
                                        ref="ket"
                                        type="textarea"
                                        :rows="2"
                                        v-model="ruleFormUpdate.ket"
                                    >
                                    </el-input>
                                </el-form-item>
                                <!-- end ket -->

                                <!-- btn -->
                                <el-form-item class="mb-1">
                                    <el-button
                                        :loading="loadingBtn"
                                        type="primary"
                                        @click="updateForm('ruleFormUpdate')"
                                        >{{ loadingBtn ? "Loading" : "Update" }}
                                    </el-button>
                                    <el-button
                                        @click="resetForm('ruleFormUpdate')"
                                        >Cancel</el-button
                                    >
                                </el-form-item>
                                <!-- end btn -->
                            </el-form>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- end dialog update -->

        <!-- dialog delete -->
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialogDelete" max-width="290">
                    <v-card>
                        <v-card-title class="text-h5">
                            Hapus Data
                        </v-card-title>
                        <v-card-text>Yakin data akan dihapus?</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="grey darken-1"
                                text
                                @click="dialogDelete = false"
                            >
                                Batal
                            </v-btn>
                            <v-btn
                                color="red darken-1"
                                text
                                @click="handleDelete"
                            >
                                Ya
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <!-- end dialog delete -->

        <!-- dialog posting -->
        <template>
            <el-dialog
                title="Posting Jurnal"
                :visible.sync="outerVisible"
                width="900px"
            >
                <!-- table -->
                <ejs-grid
                    ref="gridpdf"
                    height="200"
                    :dataSource="jurnalsPDF"
                    :allowRowDragAndDrop="true"
                    :selectionSettings="selectionAll"
                    :toolbarClick="cekData"
                    :toolbar="toolbarKirim"
                >
                    <e-columns>
                        <e-column type="checkbox" width="50"></e-column>
                        <e-column
                            field="tgl"
                            headerText="Tanggal"
                            width="120"
                            format="dMy"
                            textAlign="Center"
                        ></e-column>
                        <e-column
                            field="no_bukti"
                            headerText="No. Bukti"
                            width="120"
                            textAlign="Center"
                        ></e-column>
                        <e-column
                            field="kode"
                            headerText="Kode"
                            width="120"
                            textAlign="Center"
                        ></e-column>
                        <e-column
                            field="ket"
                            headerText="Keterangan"
                            width="150"
                        ></e-column>
                        <e-column
                            field="jml_debet"
                            headerText="Debet"
                            width="130"
                            format="N2"
                            textAlign="Right"
                        ></e-column>
                        <e-column
                            field="jml_kredit"
                            headerText="Kredit"
                            width="130"
                            format="N2"
                            textAlign="Right"
                        ></e-column>
                    </e-columns>

                    <e-aggregates>
                        <e-aggregate>
                            <e-columns>
                                <!-- utk sum all -->
                                <e-column
                                    type="Sum"
                                    format="N2"
                                    field="jml_debet"
                                    :footerTemplate="footerSum"
                                ></e-column>
                                <e-column
                                    type="Sum"
                                    format="N2"
                                    field="jml_kredit"
                                    :footerTemplate="footerSum"
                                ></e-column>
                                <!-- end sum all -->
                            </e-columns>
                        </e-aggregate>
                    </e-aggregates>
                </ejs-grid>
                <!-- end table -->

                <!-- progress -->
                <el-progress
                    class="mt-3"
                    :percentage="percentage"
                    :color="customColor"
                    :stroke-width="20"
                    :text-inside="true"
                ></el-progress>
                <!-- end progress -->

                <!-- btn -->
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="outerVisible = false"
                        >Cancel</el-button
                    >
                </div>
                <!-- end btn -->
            </el-dialog>
        </template>
        <!-- end dialog posting -->
    </v-container>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import {
    GridPlugin,
    Page,
    Edit,
    Toolbar,
    RowDD,
    Aggregate,
    Filter,
    FilterType,
} from "@syncfusion/ej2-vue-grids";
Vue.use(GridPlugin);
export default {
    data() {
        return {
            screenLoading: false,
            percentage: 0,
            customColor: "#409eff",
            outerVisible: false,
            printPDF: false,
            allowDrag: false,
            jurnals: [],
            jurnalsPDF: [],
            jurnalsPreview: [],
            dataPosting: {},
            pageSettings: { pageCount: 5, pageSize: 50 },
            selection: { type: "Single" }, //Multiple
            selectionAll: {
                type: "Multiple",
                checkboxOnly: true,
                checkboxMode: "ResetOnRowClick",
            }, //Multiple
            toolbarKirim: [
                {
                    prefixIcon: "e-icon e-save",
                    text: "Posting",
                    tooltipText: "Kirim Data",
                    id: "kiriminfo",
                },
                {
                    prefixIcon: "e-icon e-print",
                    text: "Print Preview",
                    tooltipText: "Print Preview",
                    id: "previewinfo",
                },
            ],
            toolbarOptions: [
                { text: "Add", tooltipText: "Tambah Data", id: "addinfo" },
                { text: "Edit", tooltipText: "Edit Data", id: "editinfo" },
                { text: "Delete", tooltipText: "Hapus Data", id: "hapusinfo" },
                // {
                //     prefixIcon: "e-icon e-print",
                //     text: "Print Preview",
                //     tooltipText: "Print Preview",
                //     id: "previewinfo",
                // },
                {
                    prefixIcon: "e-icon e-save",
                    text: "Preview Posting",
                    tooltipText: "Kirim Data",
                    id: "postinginfo",
                },
                // {
                //     text: "Row Drag",
                //     tooltipText: "Pindahkan Baris",
                //     id: "draginfo",
                // },
                "Search",
            ],
            dialog: false,
            maxNoBukti: "",
            maxNoBuktiShared: "",
            ruleForm: {
                id: "",
                tgl:
                    localStorage.getItem("tanggal") ||
                    new Date().toISOString().substr(0, 10),
                user_id: JSON.parse(localStorage.getItem("user")).id,
                id_perus: JSON.parse(localStorage.getItem("localDept")).idPerus,
                nobukti: "",
                jml_debet: 0,
                jml_kredit: 0,
                ket: "",
            },

            rules: {
                tgl: [
                    {
                        required: true,
                        message: "Tanggal tidak boleh kosong",
                        trigger: "blur",
                    },
                ],
                nobukti: [
                    {
                        required: true,
                        message: "No. Bukti tidak boleh kosong",
                        trigger: "blur",
                    },
                    {
                        min: 10,
                        message: "Panjang no.bukti minimal 10 karakter",
                        trigger: "blur",
                    },
                ],
                ket: [
                    {
                        required: true,
                        message: "Keterangan tidak boleh kosong",
                        trigger: "blur",
                    },
                    {
                        min: 10,
                        message: "Panjang keterangan minimal 10 karakter",
                        trigger: "blur",
                    },
                ],
            },
            ruleFormUpdate: {},
            akuns: [],
            loading: false,
            loadingBtn: false,
            msgWarning: false,
            dialogDelete: false,
            dialogUpdate: false,
            kdDebet: "",
            kdKredit: "",
            kdDebetUpdate: "",
            kdKreditUpdate: "",
            filterSettings: { type: "CheckBox" },

            footerSum: function () {
                return {
                    template: Vue.component("Sum", {
                        template: `<span> {{data.Sum}} </span>`,
                        data() {
                            return {
                                data: {},
                            };
                        },
                    }),
                };
            },
        };
    },

    mounted() {
        this.loadJurnal();
        this.loadAkun();
    },

    methods: {
        // memanggil data akun
        loadAkun: function () {
            // this.isLoading = true;

            let mytoken = localStorage.getItem("token");

            const headers = {
                // "x-csrf-token": $('meta[name="csrf-token"]').attr("content"),
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };

            axios
                .get("/api/jurnal/getAkun", { headers })
                .then((res) => {
                    this.akuns = res.data;
                    // console.log(this.akuns);
                    // this.isLoading = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        // tampilkan jurnal
        loadJurnal: function () {
            this.screenLoading = true;
            let myreq = {
                tgl: this.ruleForm.tgl,
                no_bukti: "JM",
                user_id: JSON.parse(localStorage.getItem("user")).id,
                id_perus: JSON.parse(localStorage.getItem("localDept")).idPerus,
            };

            let mytoken = localStorage.getItem("token");

            const headers = {
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };

            axios
                .post("/api/jurnal/jurnal-shared", myreq, {
                    headers,
                })
                .then((res) => {
                    this.jurnals = res.data.data;
                    this.screenLoading = false;
                    // console.log(this.jurnals);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // end tampilkan jurnal

        async loadNoBuktiJurnal() {
            this.loading = false;
            let noUrut = "01";
            let thn = this.ruleForm.tgl.substr(2, 2);
            let bln = this.ruleForm.tgl.substr(5, 2);
            let tgl = this.ruleForm.tgl.substr(8, 2);
            let req = {
                tgl: this.ruleForm.tgl,
                id_perus: this.ruleForm.id_perus,
            };
            let mytoken = localStorage.getItem("token");
            const headers = {
                // "x-csrf-token": $('meta[name="csrf-token"]').attr("content"),
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };

            //   Tampilkan data jurnal terakhir berdasarkan tgl dan id persu yang dipilih
            const resJurnal = await axios.post("/api/jurnal/bukti", req, {
                headers,
            });
            if (resJurnal.data.length > 0) {
                this.maxNoBukti = resJurnal.data[0].nobukti;
                // console.log("X : " + this.maxNoBukti);

                noUrut = parseInt(this.maxNoBukti.substr(8, 2), 0);
                this.maxNoBukti = this.maxNoBukti.substr(0, 8) + noUrut;
                // console.log("jurnal : " + this.maxNoBukti.length);

                if (this.maxNoBukti.length < 10) {
                    // console.log("9 ji");
                    noUrut = parseInt(this.maxNoBukti.substr(8, 2), 0) + 1;
                    if (noUrut < 10) {
                        noUrut = "0" + noUrut;
                    }
                } else {
                    // console.log("10 ji");
                    noUrut = parseInt(this.maxNoBukti.substr(8, 2), 0) + 1;
                }
                this.maxNoBukti = this.maxNoBukti.substr(0, 8) + noUrut;
                // console.log("A :" + this.maxNoBukti);
            } else {
                // jika data jurnal tidak ada,
                // maka buat no bukti baru
                this.maxNoBukti = "JM" + thn + bln + tgl + noUrut;
            }
        },

        async loadNoBuktiJurnalShared() {
            let noUrut = "01";
            let thn = this.ruleForm.tgl.substr(2, 2);
            let bln = this.ruleForm.tgl.substr(5, 2);
            let tgl = this.ruleForm.tgl.substr(8, 2);
            let req = {
                tgl: this.ruleForm.tgl,
                id_perus: this.ruleForm.id_perus,
            };
            let mytoken = localStorage.getItem("token");
            const headers = {
                // "x-csrf-token": $('meta[name="csrf-token"]').attr("content"),
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };

            //   Tampilkan data jurnal terakhir berdasarkan tgl dan id perus yang dipilih
            const resJurnalShared = await axios.post(
                "/api/jurnal/jurnal-shared/bukti",
                req,
                { headers }
            );

            if (resJurnalShared.data.length > 0) {
                this.maxNoBuktiShared = resJurnalShared.data[0].nobukti;
                // console.log("X : " + this.maxNoBuktiShared);

                noUrut = parseInt(this.maxNoBuktiShared.substr(8, 2), 0);
                this.maxNoBuktiShared =
                    this.maxNoBuktiShared.substr(0, 8) + noUrut;
                // console.log("jurnal : " + this.maxNoBuktiShared.length);

                if (this.maxNoBuktiShared.length < 10) {
                    // console.log("9 ji");
                    noUrut =
                        parseInt(this.maxNoBuktiShared.substr(8, 2), 0) + 1;
                    if (noUrut < 10) {
                        noUrut = "0" + noUrut;
                    }
                } else {
                    // console.log("10 ji");
                    noUrut =
                        parseInt(this.maxNoBuktiShared.substr(8, 2), 0) + 1;
                }
                this.maxNoBuktiShared =
                    this.maxNoBuktiShared.substr(0, 8) + noUrut;
                this.loading = true;
                // console.log("A :" + this.maxNoBuktiShared);
            } else {
                // jika data jurnal tidak ada,
                // maka buat no bukti baru
                this.maxNoBuktiShared = "JM" + thn + bln + tgl + noUrut;
                this.loading = true;
            }
        },

        cekData: async function (args) {
            if (args.item.id === "kiriminfo") {
                let a = this.$refs.gridpdf.ej2Instances.getSelectedRecords();
                if (a.length > 0) {
                    this.dataPosting = a;
                    this.handlePosting(a);
                } else {
                    alert("Pilih semua record");
                }
            }
            if (args.item.id === "previewinfo") {
                let a = this.$refs.gridpdf.ej2Instances.getSelectedRecords();
                if (a.length > 0) {
                    // tampung ke jurnalPreview
                    this.jurnalsPreview = [];
                    a.forEach((e) => {
                        this.jurnalsPreview.push(e);
                    });

                    this.printPDF = true;
                    this.buatPdf(a[0].no_bukti);
                } else {
                    alert("pilih semua record");
                }
            }
        },

        clickHandler: async function (args) {
            if (args.item.id === "addinfo") {
                // alert("Input Data Baru");
                this.dialog = true;
                await this.loadNoBuktiJurnal();
                await this.loadNoBuktiJurnalShared();

                //   no bukti mana yang paling besar, itu yang dipakai
                // console.log("A :", this.maxNoBukti);
                // console.log("B :", this.maxNoBuktiShared);
                if (this.maxNoBukti > this.maxNoBuktiShared) {
                    this.ruleForm.nobukti = this.maxNoBukti;
                } else if (this.maxNoBukti < this.maxNoBuktiShared) {
                    this.ruleForm.nobukti = this.maxNoBuktiShared;
                } else {
                    this.ruleForm.nobukti = this.maxNoBukti;
                }
            }
            if (args.item.id === "editinfo") {
                // console.log(this.$refs.grid.ej2Instances.getSelectedRows());
                // console.log(this.$refs.grid.ej2Instances.getSelectedRecords());
                let a = this.$refs.grid.ej2Instances.getSelectedRecords();
                if (a.length > 0) {
                    // alert(a[0].id);
                    this.dialogUpdate = true;
                    this.ruleFormUpdate = {
                        id: a[0].id,
                        tgl: a[0].tgl,
                        user_id: JSON.parse(localStorage.getItem("user")).id,
                        id_perus: JSON.parse(localStorage.getItem("localDept"))
                            .idPerus,
                        nobukti: a[0].no_bukti,
                        kode: a[0].kode,
                        jml_debet: a[0].jml_debet,
                        jml_kredit: a[0].jml_kredit,
                        ket: a[0].ket,
                        jns_transaksi: a[0].jns_transaksi,
                    };
                    if (this.ruleFormUpdate.jns_transaksi === "D") {
                        this.kdDebetUpdate = this.ruleFormUpdate.kode;
                        this.kdKreditUpdate = "";
                    } else {
                        this.kdKreditUpdate = this.ruleFormUpdate.kode;
                        this.kdDebetUpdate = "";
                    }
                } else {
                    alert("pilih record");
                }
            }
            if (args.item.id === "hapusinfo") {
                let a = this.$refs.grid.ej2Instances.getSelectedRecords();
                if (a.length > 0) {
                    // tampilkasn pesan hapus
                    this.ruleForm.id = a[0].id;
                    this.dialogDelete = true;
                } else {
                    alert("pilih record");
                }
            }
            if (args.item.id === "postinginfo") {
                let a = this.$refs.grid.ej2Instances.getSelectedRecords();
                if (a.length > 0) {
                    // alert("Posting Data");
                    this.outerVisible = true;
                    this.printPDF = false;
                    this.jurnalsPDF = [];
                    this.buatPdf(a[0].no_bukti);
                } else {
                    alert("pilih record");
                }
            }
            // if (args.item.id === "previewinfo") {
            //     let a = this.$refs.grid.ej2Instances.getSelectedRecords();
            //     if (a.length > 0) {
            //         // alert("Print Preview");
            //         this.printPDF = true;
            //         this.buatPdf(a[0].no_bukti);
            //     } else {
            //         alert("pilih record nomor bukti");
            //     }
            // }
            // if (args.item.id === "draginfo") {
            //     this.allowDrag = !this.allowDrag;
            // }
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert("submit! ");

                    if (
                        (this.kdDebet !== "" &&
                            this.kdDebet !== null &&
                            this.ruleForm.jml_debet > 0) ||
                        (this.kdKredit !== "" &&
                            this.kdKredit !== null &&
                            this.ruleForm.jml_kredit > 0)
                    ) {
                        this.msgWarning = false;
                        // alert("submit! ");
                        this.simpanKredit();
                        this.simpanDebet();
                    } else {
                        this.msgWarning = true;
                        // console.log("kode debet belum dipilih");
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialog = false;
            this.dialogUpdate = false;
        },

        formatNumber(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },

        simpanData: function () {
            let mytoken = localStorage.getItem("token");
            const headers = {
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };
            axios
                .post("/api/jurnal/jurnal-shared/create", this.form, {
                    headers,
                })
                .then((res) => {
                    this.loadJurnal();
                    this.$message({
                        showClose: true,
                        message: "Success, Jurnal berhasil dibuat.",
                        type: "success",
                    });
                    this.loadingBtn = false;
                    this.dialog = false;
                })
                .catch((err) => {
                    this.errors.record(err.response.data);
                });
        },

        simpanDebet: function () {
            this.form = {
                tgl: this.ruleForm.tgl,
                no_bukti: this.ruleForm.nobukti,
                kode: this.kdDebet,
                jml_debet: this.ruleForm.jml_debet,
                jml_kredit: 0,
                jns_transaksi: "D",
                ket: this.ruleForm.ket,
                user_id: this.ruleForm.user_id,
                id_perus: this.ruleForm.id_perus,
            };
            this.loadingBtn = true;
            this.simpanData();
        },

        simpanKredit: function () {
            this.form = {
                tgl: this.ruleForm.tgl,
                no_bukti: this.ruleForm.nobukti,
                kode: this.kdKredit,
                jml_debet: 0,
                jml_kredit: this.ruleForm.jml_kredit,
                jns_transaksi: "K",
                ket: this.ruleForm.ket,
                user_id: this.ruleForm.user_id,
                id_perus: this.ruleForm.id_perus,
            };
            this.loadingBtn = true;
            this.simpanData();
        },

        handleDelete: function (id) {
            let mytoken = localStorage.getItem("token");

            const headers = {
                // "x-csrf-token": $('meta[name="csrf-token"]').attr("content"),
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };

            axios
                .delete(
                    "/api/jurnal/jurnal-shared/delete/" + this.ruleForm.id,
                    { headers }
                )
                .then((res) => {
                    // console.log(id);
                    this.loadJurnal();
                    this.$message({
                        showClose: true,
                        message: "Delete, Jurnal berhasil dihapus.",
                        type: "success",
                    });
                    this.dialogDelete = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        updateForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert("submit! ");

                    if (
                        (this.kdDebetUpdate !== "" &&
                            this.kdDebetUpdate !== null &&
                            this.ruleFormUpdate.jml_debet > 0) ||
                        (this.kdKreditUpdate !== "" &&
                            this.kdKreditUpdate !== null &&
                            this.ruleFormUpdate.jml_kredit > 0)
                    ) {
                        this.msgWarning = false;

                        // cek, apakah data debet atau kredit yang mau diupdate
                        if (
                            this.kdDebetUpdate !== "" &&
                            this.kdDebetUpdate !== null &&
                            this.ruleFormUpdate.jml_debet > 0
                        ) {
                            this.updateDebet();
                        } else {
                            this.updateKredit();
                        }
                    } else {
                        this.msgWarning = true;
                        // console.log("kode debet belum dipilih");
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },

        updateData: function () {
            // console.log("berhasil update data");
            // this.$router.push({ name: "JurnalUmumInput" });
            let mytoken = localStorage.getItem("token");
            const headers = {
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };

            axios
                .put("/api/jurnal/jurnal-shared/update", this.okmiUpdate, {
                    headers,
                })
                .then((res) => {
                    this.loadJurnal();
                    this.$message({
                        showClose: true,
                        message: "Update, Jurnal berhasil diubah.",
                        type: "success",
                    });
                    this.loadingBtn = false;
                    this.dialogUpdate = false;
                })
                .catch((err) => {
                    this.errors.record(err.response.data);
                });
        },

        updateDebet: function () {
            this.okmiUpdate = {
                id: this.ruleFormUpdate.id,
                tgl: this.ruleFormUpdate.tgl,
                no_bukti: this.ruleFormUpdate.nobukti,
                kode: this.ruleFormUpdate.kode,
                jml_debet: this.ruleFormUpdate.jml_debet,
                jml_kredit: 0,
                jns_transaksi: "D",
                ket: this.ruleFormUpdate.ket,
                user_id: this.ruleFormUpdate.user_id,
                id_perus: this.ruleFormUpdate.id_perus,
            };
            this.loadingBtn = true;
            this.updateData();
        },

        updateKredit: function () {
            this.okmiUpdate = {
                id: this.ruleFormUpdate.id,
                tgl: this.ruleFormUpdate.tgl,
                no_bukti: this.ruleFormUpdate.nobukti,
                kode: this.ruleFormUpdate.kode,
                jml_debet: 0,
                jml_kredit: this.ruleFormUpdate.jml_kredit,
                jns_transaksi: "K",
                ket: this.ruleFormUpdate.ket,
                user_id: this.ruleFormUpdate.user_id,
                id_perus: this.ruleFormUpdate.id_perus,
            };
            this.loadingBtn = true;
            this.updateData();
        },

        buktiJurnalPdf() {
            //buat pdf

            //link contoh : https://www.codegrepper.com/code-examples/javascript/pdfmake+table+dynamic+data
            var headers = {
                fila_0: {
                    col_1: {
                        text: "No.Acc",
                        fontSize: 10,
                        alignment: "center",
                        // margin: [0, 8, 0, 0],
                    },
                    col_2: {
                        text: "Keterangan",
                        fontSize: 10,
                        alignment: "center",
                        // margin: [0, 8, 0, 0],
                    },
                    col_3: {
                        text: "Debet",
                        fontSize: 10,
                        alignment: "center",
                        // margin: [0, 8, 0, 0],
                    },
                    col_4: {
                        text: "Kredit",
                        fontSize: 10,
                        alignment: "center",
                    },
                },
            };

            var kosong = {
                fila_0: {
                    col_1: {
                        text: "",
                        fontSize: 10,
                        alignment: "center",
                        margin: [0, 6],
                    },
                    col_2: {
                        text: "",
                        fontSize: 10,
                        alignment: "center",
                        margin: [0, 6],
                    },
                    col_3: {
                        text: "",
                        fontSize: 10,
                        alignment: "center",
                        margin: [0, 6],
                    },
                    col_4: {
                        text: "",
                        fontSize: 10,
                        alignment: "center",
                        margin: [0, 6],
                    },
                },
            };

            //utk data
            var rows = this.jurnalsPreview;

            var body = [];
            for (var key in headers) {
                if (headers.hasOwnProperty(key)) {
                    var header = headers[key];
                    var row = new Array();
                    row.push(header.col_1);
                    row.push(header.col_2);
                    row.push(header.col_3);
                    row.push(header.col_4);
                    body.push(row);
                }
            }
            for (var key in rows) {
                if (rows.hasOwnProperty(key)) {
                    var data = rows[key];
                    var row = new Array();
                    row.push({
                        text: data.kode.toString(),
                        alignment: data.jns_transaksi == "D" ? "left" : "right",
                        fontSize: 10,
                        margin: [0, 2],
                    });
                    row.push({
                        text: data.ket.toString(),
                        alignment: "left",
                        fontSize: 10,
                        margin:
                            data.jns_transaksi == "D"
                                ? [0, 0, 0, 0]
                                : [10, 0, 0, 0],
                    });
                    row.push({
                        text: data.format_debet,
                        alignment: "right",
                        fontSize: 10,
                        margin: [0, 2],
                    });
                    row.push({
                        text: data.format_kredit,
                        alignment: "right",
                        fontSize: 10,
                        margin: [0, 2],
                    });
                    body.push(row);
                }
            }

            //jika data dibawah 10 baris, tambahkan baris kosong
            if (this.jurnalsPreview.length < 10) {
                let array = this.jurnalsPreview.length;
                for (let index = array; index < 10; index++) {
                    for (var key in kosong) {
                        if (kosong.hasOwnProperty(key)) {
                            var kos = kosong[key];
                            var row = new Array();
                            row.push(kos.col_1);
                            row.push(kos.col_2);
                            row.push(kos.col_3);
                            row.push(kos.col_4);
                            body.push(row);
                        }
                    }
                }
            } else {
                console.log("jumlah data lebih dari 10");
            }
            //-------------

            var dd = {
                pageMargins: [60, 20, 10, 0],
                // pageOrientation: "landscape",
                // header: function () {
                //     return {
                //         // margin: 40,
                //         columns: [
                //             {},
                //             {
                //                 text: ["Resumen disciplinario"],
                //                 alignment: "left",
                //                 bold: true,
                //                 margin: [-405, 80, 0, 0],
                //                 fontSize: 24,
                //             },
                //         ],
                //     };
                // },
                // footer: function (currentPage, pageCount) {
                //     return {
                //         text:
                //             "Pagina " +
                //             currentPage.toString() +
                //             " de " +
                //             pageCount,
                //         alignment: "center",
                //         margin: [0, 30, 0, 0],
                //     };
                // },
                content: [
                    {
                        layout: "noBorders",
                        table: {
                            widths: [350, "*"],
                            headerRows: 1,
                            body: [
                                [
                                    {
                                        rowSpan: 2,
                                        text: this.nmperus,
                                        fontSize: 14,
                                    },
                                    {
                                        text:
                                            "No. : " +
                                            this.jurnalsPDF[0].no_bukti,
                                        style: "paragraf",
                                    },
                                ],
                                [
                                    "",
                                    {
                                        text:
                                            "Tgl  : " + this.jurnalsPDF[0].tgl,
                                        style: "paragraf",
                                        margin: [60, 0, 0, 0],
                                    },
                                ],
                            ],
                        },
                    },

                    {
                        text: "BUKTI JURNAL",
                        style: "header",
                        margin: 10, //margin:[left, top, right, bottom]
                    },

                    {
                        style: "tableExample",
                        table: {
                            widths: [70, "*", 70, 70],
                            headerRows: 2,
                            // keepWithHeaderRows: 1,
                            body: body,
                        },
                    },
                    {
                        margin: [0, 3, 0, 0],
                        table: {
                            widths: [122, 122, 122, 122.3],
                            headerRows: 1,
                            // keepWithHeaderRows: 1,
                            body: [
                                [
                                    { text: "DIBUAT OLEH", style: "ttd" },
                                    { text: "DICEK OLEH", style: "ttd" },
                                    { text: "DIBUKUKAN OLEH", style: "ttd" },
                                    { text: "DISETUJUI OLEH", style: "ttd" },
                                ],
                                [
                                    { text: "", margin: [0, 25] },
                                    { text: "", margin: [0, 25] },
                                    { text: "", margin: [0, 25] },
                                    { text: "", margin: [0, 25] },
                                ],
                            ],
                        },
                    },
                ],
                styles: {
                    header: {
                        fontSize: 11,
                        bold: true,
                        alignment: "center",
                    },
                    paragraf: {
                        fontSize: 10,
                    },
                    ttd: {
                        fontSize: 10,
                        alignment: "center",
                    },
                },
            };
            pdfMake.createPdf(dd).open();
        },

        buatPdf(item) {
            let nobukti = item; //this.selected[0].no_bukti;
            let mytoken = localStorage.getItem("token");
            // console.log(this.ruleForm.id_perus);
            // console.log(this.ruleForm.user_id);
            const headers = {
                // "x-csrf-token": $('meta[name="csrf-token"]').attr("content"),
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };
            axios(
                `/api/jurnal/jurnal-shared/buktijurnal/${this.ruleForm.id_perus}/${this.ruleForm.user_id}/${nobukti}`,
                { headers }
            )
                .then((res) => {
                    // console.log(res.data.data);
                    this.jurnalsPDF = res.data.data;
                    // console.log(this.jurnalsPDF);
                    if (this.printPDF === true) {
                        this.buktiJurnalPdf();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        handlePosting(selected) {
            let mytoken = localStorage.getItem("token");
            const headers = {
                // "x-csrf-token": $('meta[name="csrf-token"]').attr("content"),
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };

            // progressbar - 1
            let putaran = Math.round(100 / selected.length);

            // perulangan;
            selected.forEach((e) => {
                const formPosting = {
                    no_urut: e.id,
                    tgl: e.tgl,
                    no_bukti: e.no_bukti,
                    kode: e.kode,
                    jml_debet: e.jml_debet,
                    jml_kredit: e.jml_kredit,
                    ket: e.ket,
                    jns_transaksi: e.jns_transaksi,
                    user_id: e.user_id,
                    id_perus: e.id_perus,
                };

                axios
                    .post("/api/jurnal/create", formPosting, { headers })
                    .then((res) => {
                        console.log(res.data);

                        // progressbar - 2
                        this.percentage += putaran;

                        //jika berhasil menyimpan data ke jurnals, hapus jurnal_shared
                        //hapus jurnal shared
                        axios
                            .delete(
                                "/api/jurnal/jurnal-shared/delete/" + e.id,
                                { headers }
                            )
                            .then((res) => {
                                // progressbar - 3
                                if (this.percentage >= 98) {
                                    // Vue.$toast.success("Jurnal berhasil dikirim", {
                                    //     position: "top-right",
                                    // });
                                    this.loadJurnal();
                                    this.$message({
                                        showClose: true,
                                        message:
                                            "Success, Data berhasil dikirim.",
                                        type: "success",
                                    });
                                    this.outerVisible = false;
                                    this.percentage = 0;
                                }
                                // end progressbar
                            })
                            .catch((err) => {
                                console.log(err);
                                this.$message({
                                    showClose: true,
                                    message: "Error, Data gagal dikirim.",
                                    type: "danger",
                                });
                            });
                        //---------------------
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
        },
    },

    provide: {
        grid: [Page, Edit, Toolbar, RowDD, Aggregate, Filter],
    },
};
</script>

<style scoped>
.v-autocomplete {
    font-size: 13px;
}

.v-text-field {
    font-size: 13px;
}

/* .my-autocomplete {
    li {
      line-height: normal;
      padding: 7px;

      .value {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .link {
        font-size: 12px;
        color: #b4b4b4;
      }
    }
  } */
</style>