<template>
    <v-container class="pa-5">
        <el-card class="mb-5" shadow="never">
            <!-- <v-card-title>Input Nomor Perkiraan</v-card-title> -->
            <div>
                <el-form
                    size="small"
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-width="150px"
                >
                    <!-- utk id -->
                    <el-input v-model="ruleForm.id" type="hidden"></el-input>

                    <!-- kode -->
                    <el-form-item label="No. Perkiraan : " prop="kode">
                        <el-input ref="kode" v-model="ruleForm.kode"></el-input>
                    </el-form-item>

                    <!-- nama rekening -->
                    <el-form-item label="Nama Perkiraan : " prop="nm_rek">
                        <el-input
                            ref="nm_rek"
                            v-model="ruleForm.nm_rek"
                        ></el-input>
                    </el-form-item>
                    <el-form-item class="mb-1">
                        <el-button
                            type="primary"
                            @click="submitForm('ruleForm')"
                            >{{ labelBtn }}</el-button
                        >
                        <el-button @click="resetForm('ruleForm')"
                            >Cancel</el-button
                        >
                    </el-form-item>
                </el-form>
            </div>
        </el-card>

        <!-- table -->
        <ejs-grid
            ref="grid"
            height="55vh"
            :allowExcelExport="true"
            :allowPdfExport="true"
            :allowSorting="true"
            :dataSource="akuns"
            :selectionSettings="selection"
            :toolbar="toolbarOptions"
            :toolbarClick="clickHandler"
            :showColumnChooser="true"
            v-loading="screenLoading"
            style="width: 100%"
        >
            <e-columns>
                <e-column
                    field="kode"
                    headerText="No. Perkiraan"
                    width="60"
                ></e-column>
                <e-column
                    field="nm_rek"
                    headerText="Nama Perkiraan"
                    width="120"
                ></e-column>
                <e-column
                    field="user_id"
                    headerText="USER"
                    width="60"
                ></e-column>
                <e-column
                    field="created_at"
                    headerText="Create At"
                    width="60"
                ></e-column>
                <e-column
                    field="updated_at"
                    headerText="Update At"
                    width="60"
                ></e-column>
            </e-columns>
        </ejs-grid>

        <!-- end table -->
    </v-container>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import {
    GridPlugin,
    Edit,
    Toolbar,
    Sort,
    PdfExport,
    ExcelExport,
    ColumnChooser,
} from "@syncfusion/ej2-vue-grids";

Vue.use(GridPlugin);

export default {
    data() {
        return {
            titleNavbar: this.$emit("titleNavbar", "Nomor Perkiraan"),
            akuns: [],
            selection: { type: "Single" }, //Multiple
            toolbarOptions: [
                "Search",
                "ColumnChooser",
                { text: "Add", tooltipText: "Tambah Data", id: "addinfo" },
                { text: "Edit", tooltipText: "Edit Data", id: "editinfo" },
                { text: "Delete", tooltipText: "Hapus Data", id: "hapusinfo" },
                "ExcelExport",
                "PdfExport",
            ],
            ruleForm: {
                id: "",
                user_id: JSON.parse(localStorage.getItem("user")).id,
                id_perus: JSON.parse(localStorage.getItem("localDept")).idPerus,
                kode: "",
                nm_rek: "",
            },
            rules: {
                kode: [
                    {
                        required: true,
                        message: "Nomor perkiraan tidak boleh kosong",
                        trigger: "blur",
                    },
                    {
                        min: 10,
                        max: 10,
                        message: "Panjang nomor harus 10 karakter",
                        trigger: "blur",
                    },
                ],
                nm_rek: [
                    {
                        required: true,
                        message: "Nama perkiraan belum diisi",
                        trigger: "blur",
                    },
                    {
                        min: 3,
                        message: "Nama yang anda masukkan terlalu pendek",
                        trigger: "blur",
                    },
                ],
            },
            labelBtn: "Simpan",
            screenLoading: false,
        };
    },
    mounted() {
        this.loadAkun();
    },

    methods: {
        clear() {
            this.ruleForm.id = "";
            this.ruleForm.kode = "";
            this.ruleForm.nm_rek = "";
        },

        //handle klik pada toolbar grid
        clickHandler: function (args) {
            if (args.item.id === "addinfo") {
                this.labelBtn = "Simpan";
                this.clear();
                this.$refs.kode.focus();
            }
            if (args.item.id === "editinfo") {
                // console.log(this.$refs.grid.ej2Instances.getSelectedRows());
                // console.log(this.$refs.grid.ej2Instances.getSelectedRecords());
                let a = this.$refs.grid.ej2Instances.getSelectedRecords();
                if (a.length > 0) {
                    this.ruleForm.id = a[0].id;
                    this.ruleForm.kode = a[0].kode;
                    this.ruleForm.nm_rek = a[0].nm_rek;
                    this.labelBtn = "Update";
                } else {
                    alert("pilih record");
                }
            }
            if (args.item.id === "hapusinfo") {
                let a = this.$refs.grid.ej2Instances.getSelectedRecords();
                if (a.length > 0) {
                    this.$confirm(
                        `Yakin Kode "${a[0].kode} - ${a[0].nm_rek}" akan di hapus?`,
                        "Warning",
                        {
                            confirmButtonText: "OK",
                            cancelButtonText: "Cancel",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            this.handleDelete(a[0].kode);
                        })
                        .catch(() => {
                            this.$message({
                                type: "info",
                                message: "Delete canceled",
                            });
                        });
                    this.clear();
                    this.labelBtn = "Simpan";
                } else {
                    alert("pilih record");
                }
            }

            // export excel pdf
            var gridInst = this.$refs.grid;
            if (gridInst) {
                if (args.item.id.includes("pdfexport")) {
                    // gridInst.getColumns()[0].visible = false;
                    // gridInst.showSpinner();
                    gridInst.pdfExport({
                        fileName: "Nomor Perkiraan.pdf",
                        // pageOrientation: "Landscape",
                        pageSize: "Letter",
                        theme: {
                            record: {
                                fontSize: 9,
                            },
                        },
                        header: {
                            fromTop: 0,
                            height: 130,
                            contents: [
                                {
                                    type: "Text",
                                    value: "tes", //this.nmperus,
                                    position: { x: 0, y: 0 },
                                    style: { fontSize: 20 },
                                },
                                {
                                    type: "Text",
                                    value: "Nomor Perkiraan",
                                    position: { x: 0, y: 50 },
                                    style: { fontSize: 16 },
                                },
                            ],
                        },
                        // footer: {
                        //     fromBottom: 130,
                        //     height: 130,
                        //     contents: [
                        //         {
                        //             type: "Text",
                        //             value: "Footer",
                        //             position: { x: 0, y: 0 },
                        //             style: { fontSize: 20 },
                        //         },
                        //         {
                        //             type: "PageNumber",
                        //             pageNumberType: "Number",
                        //             format: "Page {$current} of {$total}", //optional
                        //             position: { x: 250, y: 0 },
                        //             style: {
                        //                 // textBrushColor: "#ffff80",
                        //                 fontSize: 10,
                        //                 hAlign: "center",
                        //                 vAlign: "Right",
                        //             },
                        //         },
                        //     ],
                        // },
                    });
                } else if (args.item.id.includes("excelexport")) {
                    console.log("excel di click");
                    // gridInst.showSpinner();
                    gridInst.excelExport({
                        fileName: "Nomor Perkiraan.xlsx",
                        theme: {
                            record: {
                                fontSize: 12,
                            },
                        },
                        header: {
                            headerRows: 5,
                            rows: [
                                {
                                    cells: [
                                        {
                                            colSpan: 5,
                                            value: this.nmperus,
                                            style: {
                                                fontSize: 14,
                                                hAlign: "Center",
                                                bold: true,
                                            },
                                        },
                                    ],
                                },
                                { cells: [{ value: "" }] },
                                {
                                    cells: [
                                        {
                                            colSpan: 5,
                                            value: "Nomor Perkiraan",
                                            style: {
                                                fontSize: 12,
                                                hAlign: "Center",
                                                // bold: true,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        // footer: {
                        //     footerRows: 1,
                        //     rows: [
                        //         {
                        //             cells: [
                        //                 {
                        //                     colSpan: 4,
                        //                     value: "footer",
                        //                     style: {
                        //                         fontSize: 20,
                        //                         hAlign: "Center",
                        //                         bold: true,
                        //                     },
                        //                 },
                        //             ],
                        //         },
                        //     ],
                        // },
                    });
                }
            }
        },

        // memanggil data akun
        loadAkun: function () {
            // this.isLoading = true;
            this.screenLoading = true;
            let mytoken = localStorage.getItem("token");

            const headers = {
                // "x-csrf-token": $('meta[name="csrf-token"]').attr("content"),
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };

            axios
                .get("/api/akun", { headers })
                .then((res) => {
                    this.akuns = res.data.data;
                    this.screenLoading = false;
                    // console.log(this.akuns);
                    // this.isLoading = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        handleSubmit: function () {
            let mytoken = localStorage.getItem("token");

            const headers = {
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };
            axios
                .post("/api/akun", this.ruleForm, {
                    headers,
                })
                .then((res) => {
                    this.$message({
                        showClose: true,
                        message: "Data berhasil ditambahkan",
                        type: "success",
                    });
                    this.loadAkun();
                    this.clear();
                })
                .catch((err) => {
                    // console.log(err.response.data);
                    this.$message({
                        showClose: true,
                        message: "Oops, periksa kembali data yang dimasukkan",
                        type: "error",
                    });
                });
        },

        handleUpdate: function () {
            let mytoken = localStorage.getItem("token");
            const headers = {
                // "x-csrf-token": $('meta[name="csrf-token"]').attr("content"),
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };
            axios
                .put("/api/akun", this.ruleForm, { headers })
                .then((res) => {
                    this.$message({
                        showClose: true,
                        message: "Data berhasil diupdate",
                        type: "success",
                    });
                    this.loadAkun();
                    this.clear();
                })
                .catch((err) => {
                    this.$message({
                        showClose: true,
                        message: "Oops, periksa kembali data yang dimasukkan",
                        type: "error",
                    });
                });
        },

        handleDelete: function (kode) {
            let mytoken = localStorage.getItem("token");

            const headers = {
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };
            axios
                .delete("/api/akun/" + kode, {
                    headers,
                })
                .then((res) => {
                    this.loadAkun();
                    this.$message({
                        showClose: true,
                        message: "Data berhasil dihapus",
                        type: "success",
                    });
                    this.clear();
                })
                .catch((err) => {
                    this.$message({
                        showClose: true,
                        message: "Oops, periksa kembali data yang dimasukkan",
                        type: "error",
                    });
                });
        },

        // handle form
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.labelBtn == "Simpan") {
                        this.handleSubmit();
                    } else {
                        this.handleUpdate();
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.ruleForm.id = "";
            this.labelBtn = "Simpan";
            this.$refs[formName].resetFields();
        },
    },

    provide: {
        grid: [Edit, Toolbar, Sort, PdfExport, ExcelExport, ColumnChooser],
    },
};
</script>

<style scoped>
.demo-input-label {
    display: inline-block;
    width: 130px;
}
</style>