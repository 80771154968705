<template>
  <v-container>
    <div class="col-lg-12 control-section mont-font">
      <h3 class="red--text">Jurnal Selisih</h3>
      <div class="mt-1 mb-7">
        <ejs-grid
          :dataSource="data"
          :allowPaging="true"
          :pageSettings="pageSettings"
        >
          <e-columns>
            <e-column
              field="OrderID"
              headerText="Order ID"
              width="120"
              textAlign="Right"
            ></e-column>
            <e-column
              field="CustomerName"
              headerText="Customer Name"
              width="150"
            ></e-column>
            <e-column
              field="OrderDate"
              headerText="Order Date"
              width="130"
              format="yMd"
              textAlign="Right"
            ></e-column>
            <e-column
              field="Freight"
              headerText="Freight"
              width="120"
              format="C2"
              textAlign="Right"
            ></e-column>
            <e-column
              field="ShippedDate"
              headerText="Shipped Date"
              width="130"
              format="yMd"
              textAlign="Right"
            ></e-column>
            <e-column
              field="ShipCountry"
              headerText="Ship Country"
              width="150"
            ></e-column>
          </e-columns>
        </ejs-grid>
      </div>

      <v-divider></v-divider>
      <h3 class="mb-1 teal--text mont-font">Create Jurnal</h3>
      <el-tabs v-model="activeName" @tab-click="handleTab">
        <el-tab-pane label="Jurnal UMUM" name="first"
          ><JurnalUmum
        /></el-tab-pane>
        <el-tab-pane label="Jurnal KAS" name="second"
          ><JurnalKas
        /></el-tab-pane>
        <el-tab-pane label="Jurnal BANK" name="third"
          ><JurnalBank
        /></el-tab-pane>
        <el-tab-pane label="Jurnal FINANCE" name="fourth"
          ><JurnalFinance
        /></el-tab-pane>
      </el-tabs>
    </div>
  </v-container>
</template>


<script>
import Vue from "vue";
import { GridPlugin, Page } from "@syncfusion/ej2-vue-grids";
import JurnalUmum from "../../../components/jurnals/JurnalUmum.vue";
import JurnalKas from "../../../components/jurnals/JurnalKas.vue";
import JurnalBank from "../../../components/jurnals/JurnalBank.vue";
import JurnalFinance from "../../../components/jurnals/JurnalFinance.vue";

Vue.use(GridPlugin);

export default {
  components: {
    JurnalUmum,
    JurnalKas,
    JurnalBank,
    JurnalFinance,
  },
  data() {
    return {
      titleNavbar: this.$emit("titleNavbar", "Jurnal"),
      data: [],
      pageSettings: { pageCount: 5 },
      activeName: "first",
    };
  },
  methods: {
    handleTab(tab, event) {
      console.log(tab, event);
    },
  },
  provide: {
    grid: [Page],
  },
};
</script>

