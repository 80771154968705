<template>
    <v-container class="pa-5">
        <el-button @click="pdfKlik">test pdf</el-button>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            titleNavbar: this.$emit("titleNavbar", "Home"),
        };
    },

    methods: {
        pdfKlik() {
            var headers = {
                fila_0: {
                    col_1: {
                        text: "Faltas",
                        style: "tableHeader",
                        rowSpan: 2,
                        alignment: "center",
                        margin: [0, 8, 0, 0],
                    },
                    col_2: {
                        text: "Fecha",
                        style: "tableHeader",
                        rowSpan: 2,
                        alignment: "center",
                        margin: [0, 8, 0, 0],
                    },
                    col_3: {
                        text: "Descripción",
                        style: "tableHeader",
                        rowSpan: 2,
                        alignment: "center",
                        margin: [0, 8, 0, 0],
                    },
                    col_4: {
                        text: "Cita con acudientes",
                        style: "tableHeader",
                        colSpan: 2,
                        alignment: "center",
                    },
                },
                fila_1: {
                    col_1: {
                        text: "Header 1",
                        style: "tableHeader",
                        alignment: "center",
                    },
                    col_2: {
                        text: "Header 2",
                        style: "tableHeader",
                        alignment: "center",
                    },
                    col_3: {
                        text: "Header 3",
                        style: "tableHeader",
                        alignment: "center",
                    },
                    col_4: {
                        text: "Citación",
                        style: "tableHeader",
                        alignment: "center",
                    },
                    col_5: {
                        text: "Cumplimiento",
                        style: "tableHeader",
                        alignment: "center",
                    },
                },
            };
            var rows = {
                a: {
                    peaje: "1",
                    ruta: "2",
                    fechaCruce: "3",
                    hora: "4",
                    valor: "5",
                },
                b: {
                    peaje: "1",
                    ruta: "2",
                    fechaCruce: "3",
                    hora: "4",
                    valor: "5",
                },
            };

            var body = [];
            for (var key in headers) {
                if (headers.hasOwnProperty(key)) {
                    var header = headers[key];
                    var row = new Array();
                    row.push(header.col_1);
                    row.push(header.col_2);
                    row.push(header.col_3);
                    row.push(header.col_4);
                    row.push(header.col_5);
                    body.push(row);
                }
            }
            for (var key in rows) {
                if (rows.hasOwnProperty(key)) {
                    var data = rows[key];
                    var row = new Array();
                    row.push(data.peaje.toString());
                    row.push(data.ruta.toString());
                    row.push(data.fechaCruce.toString());
                    row.push(data.hora.toString());
                    row.push(data.valor.toString());
                    body.push(row);
                }
            }

            var dd = {
                pageMargins: [40, 155, 40, 55],
                pageOrientation: "landscape",
                header: function () {
                    return {
                        margin: 40,
                        columns: [
                            {},
                            {
                                text: ["Resumen disciplinario"],
                                alignment: "left",
                                bold: true,
                                margin: [-405, 80, 0, 0],
                                fontSize: 24,
                            },
                        ],
                    };
                },
                footer: function (currentPage, pageCount) {
                    return {
                        text:
                            "Pagina " +
                            currentPage.toString() +
                            " de " +
                            pageCount,
                        alignment: "center",
                        margin: [0, 30, 0, 0],
                    };
                },
                content: [
                    //{ text: 'Tables', style: 'header' },
                    "\nEl estudiante AGRESOTH NEGRETE JORYETH TATIANA - 901 - TARDE tiene 1 actas, con 1 faltas acomuladas y a manera de resumen descritas a continuación:\n\n",
                    //{ text: 'A simple table (no headers, no width specified, no spans, no styling)', style: 'sta' },
                    //'The following table has nothing more than a body array',
                    {
                        style: "tableExample",
                        table: {
                            widths: ["*", "*", "*", "*", "*"],
                            headerRows: 2,
                            // keepWithHeaderRows: 1,
                            body: body,
                        },
                    },
                ],
                defaultStyle: {
                    font: "yourFontName",
                },
                styles: {
                    header: {
                        fontSize: 28,
                        bold: true,
                    },
                    subheader: {
                        fontSize: 15,
                        bold: true,
                    },
                    quote: {
                        italics: true,
                    },
                    small: {
                        fontSize: 8,
                    },
                    sta: {
                        fontSize: 11,
                        bold: false,
                        alignment: "justify",
                    },
                },
            };
            pdfMake.fonts = {
                yourFontName: {
                    normal: "https://drive.google.com/file/d/1kLk313K-4uj-KhcX35qAFk-hMvCNr2Zg/view?usp=sharing",
                    bold: "https://drive.google.com/file/d/1kLk313K-4uj-KhcX35qAFk-hMvCNr2Zg/view?usp=sharing",
                    italics:
                        "https://drive.google.com/file/d/1kLk313K-4uj-KhcX35qAFk-hMvCNr2Zg/view?usp=sharing",
                    bolditalics:
                        "https://drive.google.com/file/d/1kLk313K-4uj-KhcX35qAFk-hMvCNr2Zg/view?usp=sharing",
                },
                Roboto: {
                    normal: "Roboto-Regular.ttf",
                    bold: "Roboto-Medium.ttf",
                    italics: "Roboto-Italic.ttf",
                    bolditalics: "Roboto-MediumItalic.ttf",
                },

                // example of usage fonts in collection
                // PingFangSC: {
                //     normal: [
                //         "https://example.com/fonts/pingfang.ttc",
                //         "PingFangSC-Regular",
                //     ],
                //     bold: [
                //         "https://example.com/fonts/pingfang.ttc",
                //         "PingFangSC-Semibold",
                //     ],
                // },
            };
            pdfMake.createPdf(dd).open();
        },
    },
};
</script>