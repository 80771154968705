<template>
    <v-container fluid>
        <v-data-iterator
            :items="items"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :search="search"
            hide-default-footer
        >
            <template v-slot:header>
                <v-toolbar dark color="blue darken-3" class="mb-1">
                    <v-text-field
                        v-model="search"
                        clearable
                        flat
                        solo-inverted
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        label="Search"
                    ></v-text-field>
                </v-toolbar>
            </template>

            <template v-slot:default="props">
                <v-row class="mt-3">
                    <v-col
                        class="py-1"
                        v-for="item in props.items"
                        :key="item.id"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                    >
                        <v-card>
                            <v-list dense>
                                <v-list-item
                                    v-for="(key, index) in filteredKeys"
                                    :key="index"
                                    @click="
                                        handleClick(item.id_perus, item.nama)
                                    "
                                >
                                    <v-list-item-content>
                                        {{ item.id_perus }} -
                                        {{ item[key.toLowerCase()] }}
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
    </v-container>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            isLogged: false,
            itemsPerPageArray: [4, 8, 12],
            search: "",
            filter: {},
            sortDesc: false,
            page: 1,
            itemsPerPage: 4,
            sortBy: "nama",
            keys: ["nama"],
            items: [],
        };
    },
    computed: {
        numberOfPages() {
            return Math.ceil(this.items.length / this.itemsPerPage);
        },
        filteredKeys() {
            return this.keys.filter((key) => key !== "Id");
        },
    },
    methods: {
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1;
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1;
        },
        updateItemsPerPage(number) {
            this.itemsPerPage = number;
        },
        handleClick(id_perus, nama) {
            localStorage.setItem("isValid", true);

            //save local departemen
            localStorage.setItem(
                "localDept",
                JSON.stringify({ idPerus: id_perus, nmPerus: nama })
            );

            //kirim parameter ke parent
            this.$emit("isLogin", localStorage.getItem("islogin"));
            this.$emit("myNavbar", localStorage.getItem("myNavbar"));
            this.$emit("localDept", localStorage.getItem("localDept"));

            this.$router.push({ name: "Home" });
        },
    },

    mounted() {
        let user = JSON.parse(localStorage.getItem("user"));

        let mytoken = localStorage.getItem("token");
        const headers = {
            // "x-csrf-token": $('meta[name="csrf-token"]').attr("content"),
            Authorization: "Bearer " + mytoken,
            Accept: "application/json",
        };

        axios
            .post("/api/getAksesDept", { user_id: user.id }, { headers })
            .then((res) => {
                console.log(res);
                this.items = res.data.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },
};
</script>
