<template>
    <v-container>
        <h2>jurnal kas</h2>
    </v-container>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>