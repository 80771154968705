<template>
    <v-container class="fill-height mont-font">
        <v-row justify="center" align="center">
            <v-col cols="12" sm="6" md="6">
                <el-card :body-style="{ padding: 0 }" shadow="hover">
                    <v-row>
                        <v-col
                            cols="12"
                            md="4"
                            class="blue-grey lighten-2"
                            v-if="hideImg"
                        >
                            <img
                                src="/img/undraw_personalization_triu.svg"
                                alt=""
                                width="500px"
                            />
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-card-text>
                                <h1
                                    class="
                                        text-center
                                        mb-12
                                        blue-grey--text
                                        darken-1
                                    "
                                >
                                    Login
                                </h1>
                                <v-form>
                                    <!-- username -->
                                    <v-text-field
                                        label="Username"
                                        autocomplete="off"
                                        prepend-icon="mdi-account"
                                        v-model="form.email"
                                    >
                                    </v-text-field>
                                    <!-- end username -->

                                    <!-- password -->
                                    <v-text-field
                                        label="Password"
                                        autocomplete="off"
                                        :type="ifClick ? 'password' : 'text'"
                                        prepend-icon="mdi-lock"
                                        v-model="form.password"
                                        ><v-icon
                                            slot="append"
                                            @click="showHidePassword"
                                        >
                                            {{
                                                ifClick
                                                    ? "mdi-eye-off"
                                                    : "mdi-eye"
                                            }}
                                        </v-icon>
                                    </v-text-field>
                                    <!-- end password -->

                                    <!-- btn -->
                                    <v-btn
                                        block
                                        rounded
                                        color="blue-grey darken-1"
                                        dark
                                        class="mt-3"
                                        @click="handleLogin"
                                        :loading="isLoading"
                                        >Login</v-btn
                                    >
                                    <!-- end btn -->
                                </v-form>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </el-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            isLoading: false,
            hideImg: true,
            ifClick: true,
            windowWidth: window.outerWidth,
            txt: "",
            step: 1,
            form: {
                email: "",
                password: "",
            },
            ruleForm: {
                kdakun: "",
                nmakun: "",
            },
            rules: {
                kdakun: [
                    {
                        required: true,
                        message: "Nomor perkiraan tidak boleh kosong",
                        trigger: "blur",
                    },
                    {
                        min: 10,
                        max: 10,
                        message: "Panjang nomor harus 10 karakter",
                        trigger: "blur",
                    },
                ],
                nmakun: [
                    {
                        required: true,
                        message: "Nama perkiraan belum diisi",
                        trigger: "blur",
                    },
                    {
                        min: 3,
                        message: "Nama yang anda masukkan terlalu sedikit",
                        trigger: "blur",
                    },
                ],
            },
        };
    },

    watch: {
        windowWidth(newWidth, oldWidth) {
            this.txt = `it changed to ${newWidth} from ${oldWidth}`;
            console.log(`it changed to ${newWidth} from ${oldWidth}`);
            if (newWidth < 975) {
                this.hideImg = false;
            } else {
                this.hideImg = true;
            }
        },
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener("resize", this.onResize);
        });
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },

    methods: {
        onResize() {
            this.windowWidth = window.outerWidth;
        },
        showHidePassword() {
            this.ifClick = !this.ifClick;
        },

        // handle login
        handleLogin: function () {
            this.isLoading = true;

            const headers = {
                Accept: "application/json",
            };

            axios
                .post("/api/login", this.form, { headers })
                .then((res) => {
                    // save if login
                    localStorage.setItem("isLogged", true);

                    // simpan ke localStore
                    localStorage.setItem(
                        "user",
                        JSON.stringify(res.data.data.user)
                    );
                    localStorage.setItem("token", res.data.data.token);

                    //go to dept page
                    this.$router.push({ name: "Dept" });

                    this.isLoading = false;
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoading = false;
                    this.msg = err.response.data.message;

                    this.$message({
                        showClose: true,
                        message: "Oops, " + this.msg,
                        type: "error",
                    });
                    //   console.log(this.msg);
                });
            // });
        },
    },
};
</script>

<style scoped>
.v-text-field {
    font-size: 14px;
}

.background-body {
    background: url("/img/undraw_version_control_re_mg66.svg");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

img {
    object-position: -50px 0px;
    width: 300px;
    height: 280px;
}
</style>

