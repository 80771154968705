<template>
    <v-app>
        <v-navigation-drawer v-model="drawer" app v-if="isValid">
            <!-- Nama Perus -->
            <v-list-item class="blue-grey darken-2" style="height: 64px" dark>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        {{ localDept.nmPerus }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ localDept.idPerus }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <!-- end Nama Perus -->

            <!-- <v-divider></v-divider> -->

            <v-list dense nav shaped>
                <!-- Dasboard -->
                <!-- <v-subheader>Dashboard</v-subheader> -->
                <v-list-item-group v-model="selectedItem" color="primary">
                    <v-list-item
                        v-for="(item, i) in items"
                        :key="i"
                        link
                        :to="item.route"
                    >
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="item.text"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>

                <!-- GL -->
                <v-subheader>General Leadger</v-subheader>
                <v-list-item-group v-model="selectedItem" color="primary">
                    <v-list-item
                        v-for="(gl, i) in generals"
                        :key="i"
                        link
                        :to="{ name: gl.route }"
                    >
                        <v-list-item-icon>
                            <v-icon v-text="gl.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="gl.text"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-group
                        :value="false"
                        prepend-icon="mdi-table-large"
                        no-action
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>Laporan</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item
                            v-for="(lap, i) in laporans"
                            :key="i"
                            link
                            :to="{ name: lap.route }"
                        >
                            <v-list-item-title
                                v-text="lap.text"
                            ></v-list-item-title>

                            <v-list-item-icon>
                                <v-icon v-text="lap.icon"></v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </v-list-item-group>
                <!-- END GL -->

                <!-- SETTINGS -->
                <v-subheader>Settings</v-subheader>
                <v-list-item-group v-model="selectedItem" color="primary">
                    <v-list-item
                        v-for="setting in settings"
                        :key="setting.text"
                        link
                        :to="setting.route"
                    >
                        <v-list-item-icon>
                            <v-icon v-text="setting.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="setting.text"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
                <!-- END SETTINGS -->
            </v-list>
        </v-navigation-drawer>

        <v-app-bar
            app
            v-if="isValid"
            color="blue-grey darken-1"
            elevation="0"
            dark
            class="mont-font"
        >
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>{{ titleNavbar }}</v-toolbar-title>

            <!-- icon avatar kanan -->
            <v-spacer></v-spacer>

            <span class="mr-3">username</span>

            <!-- dropdown menu -->
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <!-- avatar -->
                    <v-btn
                        fab
                        small
                        v-bind="attrs"
                        v-on="on"
                        elevation="0"
                        light
                    >
                        <v-icon>mdi-account</v-icon>
                    </v-btn>
                </template>

                <v-list width="250px" dense>
                    <v-list-item link :to="{ name: 'Dept' }">
                        <v-list-item-title>
                            Pilih Departemen
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout">
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <!-- end dropdown menu -->
        </v-app-bar>

        <v-main>
            <router-view
                @localDept="getDepartemen($event)"
                @titleNavbar="getTitleNavbar($event)"
            ></router-view>
        </v-main>
    </v-app>
</template>

<script>
export default {
    // name: "App",
    data: () => ({
        selectedItem: 0,
        username: "",
        localDept: [],
        isLogged: localStorage.getItem("isLogged") || false,
        isValid: localStorage.getItem("isValid") || false,
        titleNavbar: "",
        drawer: null,
        items: [
            { icon: "mdi-home", text: "Dashboard", route: "/" },
            // { icon: "mdi-domain", text: "About", route: "/about" },
        ],
        generals: [
            { icon: "mdi-stove", text: "Nomor Perkiraan", route: "Akun" },
            { icon: "mdi-table", text: "Jurnal", route: "Jurnal" },
        ],

        settings: [
            { icon: "mdi-account-circle", text: "Users", route: "Users" },
            { icon: "mdi-home", text: "Setting", route: "Setting" },
        ],
        depts: [
            { text: "A", route: "Home" },
            { text: "B", route: "Home" },
            { text: "C", route: "Home" },
        ],
        laporans: [
            {
                icon: "mdi-home",
                text: "Jurnal Transaksi",
                route: "LapJurnal",
            },
            { icon: "mdi-home", text: "Neraca", route: "LapNeraca" },
            { icon: "mdi-home", text: "Buku Besar", route: "LapBukuBesar" },
            { icon: "mdi-home", text: "Rugi Laba", route: "LapRugiLaba" },
        ],
    }),

    mounted() {
        // cek username

        this.localDept = JSON.parse(localStorage.getItem("localDept")) || [];

        if (this.isLogged === false || localStorage.isLogged === false) {
            if (this.isValid === false || localStorage.isValid === false) {
                this.$router.push({ name: "Login" });
            } else {
                this.$router.push({ name: "Dept" });
            }
        } else {
            if (this.isValid === false || localStorage.isValid === false) {
                this.$router.push({ name: "Dept" });
            } else {
                this.$router.push({ name: "Home" });
            }
        }
    },

    updated() {
        this.isValid = localStorage.getItem("isValid") || false;
    },

    methods: {
        // getUsername() {
        //     // const a = localStorage.setItem(
        //     //     "user",
        //     //     JSON.stringify({ name: "" })
        //     // );
        //     localStorage.setItem("user", JSON.stringify({ name: "" }));
        //     const a = JSON.parse(localStorage.getItem("user")).name;
        //     if (a === null) {
        //         this.username = "";
        //     } else {
        //         this.username = JSON.parse(localStorage.getItem("user")).name;
        //     }
        // },
        getTitleNavbar(a) {
            this.titleNavbar = a;
        },

        getDepartemen(a) {
            this.localDept = JSON.parse(a) || [];
            // console.log("from deptsdfsdf - " + this.localDept.nmPerus);
        },

        logout() {
            localStorage.clear();
            this.$router.push({ name: "Login" });
        },
    },
};
</script>

<style>
@import url(https://cdn.syncfusion.com/ej2/material.css);
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");

.mont-font {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}
.mont-font-semi {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}
.mont-font-bold {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}
.my-text-style {
    font-size: 14px !important;
}
</style>
