<template>
    <v-container>
        <v-tabs>
            <v-tab @click="tab1Click">By Tanggal Transaksi</v-tab>
            <v-tab>By Nomor Bukti</v-tab>

            <!-- isi tab1 -->
            <v-tab-item :key="1">
                <v-row class="mt-5">
                    <v-col cols="12" md="4" class="py-0 my-0">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    class="my-text-style"
                                    v-model="date"
                                    label="dari tanggal"
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="4" class="py-0 my-0">
                        <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :return-value.sync="date2"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    class="my-text-style"
                                    v-model="date2"
                                    label="sampai tanggal"
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date2" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu2 = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu2.save(date2)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-tab-item>
            <!-- end isi tab1 -->

            <!-- isi tab2 -->
            <v-tab-item :key="2">
                <v-row class="mt-2">
                    <v-col cols="12" md="4">
                        <v-text-field
                            class="my-text-style"
                            v-model="search"
                            label="Masukkan nomor bukti"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-tab-item>
            <!-- end isi tab2 -->
        </v-tabs>

        <!-- tombol proses -->
        <v-btn
            elevation="0"
            class="mt-2 mb-5"
            :disabled="isLogging"
            :loading="isLogging"
            @click="loadJurnal"
            >{{ isLogging ? "Logging..." : "Proses" }}</v-btn
        >
        <!-- end tombol proses -->

        <!-- table -->
        <ejs-grid
            ref="grid"
            height="300"
            :dataSource="dataJurnal"
            :allowGrouping="true"
            :allowPdfExport="true"
            :allowExcelExport="true"
            :allowTextWrap="true"
            :allowResizing="false"
            :groupSettings="groupOptions"
            :toolbar="toolbarOptions"
            :toolbarClick="gridExport"
            :excelExportComplete="excelExportComplete"
            :pdfExportComplete="pdfExportComplete"
        >
            <e-columns>
                <e-column
                    field="tgl"
                    headerText="Tanggal"
                    textAlign="center"
                    width="120"
                ></e-column>
                <e-column
                    field="no_bukti"
                    headerText="No.Bukti"
                    textAlign="center"
                    width="120"
                ></e-column>
                <e-column
                    field="ket"
                    headerText="Keterangan"
                    textAlign="left"
                    width="200"
                ></e-column>
                <e-column
                    field="kode"
                    headerText="Kode"
                    textAlign="center"
                    width="120"
                ></e-column>
                <e-column
                    field="nm_rek"
                    headerText="Nama Rekening"
                    textAlign="left"
                    width="200"
                ></e-column>
                <e-column
                    field="jml_debet"
                    headerText="Debet"
                    textAlign="right"
                    width="120"
                ></e-column>
                <e-column
                    field="jml_kredit"
                    headerText="Kredit"
                    textAlign="right"
                    width="120"
                ></e-column>
            </e-columns>
            <e-aggregates>
                <e-aggregate>
                    <e-columns>
                        <!-- utk sum all -->
                        <e-column
                            type="Sum"
                            format="N2"
                            field="jml_debet"
                            :footerTemplate="footerSum"
                        ></e-column>
                        <e-column
                            type="Sum"
                            format="N2"
                            field="jml_kredit"
                            :footerTemplate="footerSum"
                        ></e-column>
                        <!-- end sum all -->

                        <!-- sum per group -->
                        <e-column
                            type="Sum"
                            format="N2"
                            field="jml_debet"
                            :groupFooterTemplate="groupFooterSum"
                        ></e-column>
                        <e-column
                            type="Sum"
                            format="N2"
                            field="jml_kredit"
                            :groupFooterTemplate="groupFooterSum"
                        ></e-column>
                        <!-- end sum per group -->
                    </e-columns>
                </e-aggregate>
            </e-aggregates>
        </ejs-grid>
        <!-- end table -->
    </v-container>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import {
    GridPlugin,
    Group,
    Aggregate,
    Toolbar,
    PdfExport,
    ExcelExport,
} from "@syncfusion/ej2-vue-grids";

Vue.use(GridPlugin);

export default {
    data() {
        return {
            titleNavbar: this.$emit("titleNavbar", "Laporan Jurnal Transaksi"),
            idperus: JSON.parse(localStorage.getItem("localDept")).idPerus,
            nmperus: JSON.parse(localStorage.getItem("localDept")).nmPerus,
            userid: JSON.parse(localStorage.getItem("user")).id,
            search: "",
            dataJurnal: [],
            date: new Date().toISOString().substr(0, 10),
            date2: new Date().toISOString().substr(0, 10),
            menu: false,
            menu2: false,
            isLogging: false,

            groupOptions: {
                columns: ["no_bukti"],
                showDropArea: true,
            },
            toolbarOptions: ["ExcelExport"],
            footerSum: function () {
                return {
                    template: Vue.component("Sum", {
                        template: `<span>Total : {{data.Sum}} </span>`,
                        data() {
                            return {
                                data: {},
                            };
                        },
                    }),
                };
            },
            groupFooterSum: function () {
                return {
                    template: Vue.component("Sum", {
                        template: `<span> {{data.Sum}} </span>`,
                        data() {
                            return {
                                data: {},
                            };
                        },
                    }),
                };
            },
        };
    },

    methods: {
        loadJurnal: function () {
            this.isLogging = true;

            let req = {
                id_perus: this.idperus,
                dari_tgl: this.date,
                sampai_tgl: this.date2,
                no_bukti: this.search,
            };
            let mytoken = localStorage.getItem("token");
            const headers = {
                // "x-csrf-token": $('meta[name="csrf-token"]').attr("content"),
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };
            axios
                .post("/api/jurnal/lapjurnal", req, {
                    headers,
                })
                .then((res) => {
                    this.dataJurnal = res.data.data;
                    // console.log(this.dataJurnal);
                    this.isLogging = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        tab1Click() {
            this.search = "";
        },

        gridExport(args) {
            var gridInst = this.$refs.grid;
            if (gridInst) {
                if (args.item.id.includes("pdfexport")) {
                    // gridInst.getColumns()[0].visible = false;
                    gridInst.showSpinner();
                    gridInst.pdfExport({
                        fileName: "jurnal-transaksi.pdf",
                        pageOrientation: "Landscape",
                        pageSize: "Letter",
                        theme: {
                            record: {
                                fontSize: 9,
                            },
                        },
                        header: {
                            fromTop: 0,
                            height: 130,
                            contents: [
                                {
                                    type: "Text",
                                    value: this.nmperus,
                                    position: { x: 0, y: 0 },
                                    style: { fontSize: 20 },
                                },
                                {
                                    type: "Text",
                                    value: "Laporan Jurnal Transaksi",
                                    position: { x: 0, y: 50 },
                                    style: { fontSize: 16 },
                                },
                                {
                                    type: "Text",
                                    value:
                                        "Tanggal " +
                                        this.date +
                                        " s/d " +
                                        this.date2,
                                    position: { x: 0, y: 70 },
                                    style: { fontSize: 16 },
                                },
                            ],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents: [
                                {
                                    type: "Text",
                                    value: "Footer",
                                    position: { x: 0, y: 0 },
                                    style: { fontSize: 20 },
                                },
                                {
                                    type: "PageNumber",
                                    pageNumberType: "Number",
                                    format: "Page {$current} of {$total}", //optional
                                    position: { x: 250, y: 0 },
                                    style: {
                                        // textBrushColor: "#ffff80",
                                        fontSize: 10,
                                        hAlign: "center",
                                        vAlign: "Right",
                                    },
                                },
                            ],
                        },
                    });
                } else if (args.item.id.includes("excelexport")) {
                    console.log("excel di click");
                    gridInst.showSpinner();
                    gridInst.excelExport({
                        fileName: "jurnal-transaksi.xlsx",
                        theme: {
                            record: {
                                fontSize: 12,
                            },
                        },
                        header: {
                            headerRows: 5,
                            rows: [
                                {
                                    cells: [
                                        {
                                            colSpan: 7,
                                            value: this.nmperus,
                                            style: {
                                                fontSize: 14,
                                                // hAlign: "Center",
                                                bold: true,
                                            },
                                        },
                                    ],
                                },
                                { cells: [{ value: "" }] },
                                {
                                    cells: [
                                        {
                                            colSpan: 7,
                                            value: "Laporan Transaksi Jurnal",
                                            style: {
                                                fontSize: 12,
                                                hAlign: "Right",
                                                // bold: true,
                                            },
                                        },
                                    ],
                                },
                                {
                                    cells: [
                                        {
                                            colSpan: 7,
                                            value:
                                                "Tanggal " +
                                                this.date +
                                                " s/d " +
                                                this.date2,
                                            style: {
                                                fontSize: 12,
                                                hAlign: "Right",
                                                // bold: true,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        // footer: {
                        //     footerRows: 1,
                        //     rows: [
                        //         {
                        //             cells: [
                        //                 {
                        //                     colSpan: 4,
                        //                     value: "footer",
                        //                     style: {
                        //                         fontSize: 20,
                        //                         hAlign: "Center",
                        //                         bold: true,
                        //                     },
                        //                 },
                        //             ],
                        //         },
                        //     ],
                        // },
                    });
                }
            }
        },

        pdfExportComplete(args) {
            this.$refs.grid.hideSpinner();
        },

        excelExportComplete(args) {
            this.$refs.grid.hideSpinner();
        },
    },

    provide: {
        grid: [Group, Aggregate, Toolbar, PdfExport, ExcelExport],
    },
};
</script>
<style>
/* @import "../node_modules/@syncfusion/ej2-vue-grids/styles/material.css"; */
/* @import url(https://cdn.syncfusion.com/ej2/material.css); */

.my-text-style {
    font-size: 14px !important;
}
</style>
