import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'
import PilihDept from '../views/departemen/PilihDept.vue'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Setting from '../views/Setting.vue'
import Perkiraan from '../views/gl/perkiraan/Akun.vue'
import Jurnal from '../views/gl/jurnal/Jurnal.vue'
import LapJurnal from '../views/gl/LapJurnal.vue'
import LapNeraca from '../views/gl/LapNeraca.vue'
import LapBukuBesar from '../views/gl/LapBukuBesar.vue'
import LapRugiLaba from '../views/gl/LapRugiLaba.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },


  {
    path: '/register',
    name: 'Register',
    component: Register,
  },


  {
    path: '/dept',
    name: 'Dept',
    component: PilihDept,
  },


  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },

  // GL
  {
    path: '/akun',
    name: 'Akun',
    component: Perkiraan,
  },
  {
    path: '/jurnal',
    name: 'Jurnal',
    component: Jurnal,

  },
  {
    path: '/lapjurnal',
    name: 'LapJurnal',
    component: LapJurnal,

  },
  {
    path: '/lapneraca',
    name: 'LapNeraca',
    component: LapNeraca,

  },
  {
    path: '/lapbb',
    name: 'LapBukuBesar',
    component: LapBukuBesar,

  },
  {
    path: '/laprl',
    name: 'LapRugiLaba',
    component: LapRugiLaba,

  },
  {
    path: '/setting',
    name: 'Setting',
    component: Setting,
  },


]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
