<template>
    <v-container>
        <!-- input -->
        <v-row class="mt-1">
            <v-col cols="12" md="4" class="py-0 my-0">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            class="my-text-style"
                            v-model="date"
                            label="dari tanggal"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(date)"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="4" class="py-0 my-0">
                <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="date2"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            class="my-text-style"
                            v-model="date2"
                            label="sampai tanggal"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="date2" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu2 = false">
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menu2.save(date2)"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <!-- end input -->

        <!-- tombol proses -->
        <v-btn
            elevation="0"
            class="mt-2 mb-5"
            :disabled="isLogging"
            :loading="isLogging"
            @click="loadJurnal"
            >{{ isLogging ? "Logging..." : "Proses" }}</v-btn
        >
        <!-- end tombol proses -->

        <!-- table -->
        <ejs-grid
            ref="grid"
            height="300"
            :dataSource="dataJurnal"
            :allowGrouping="true"
            :allowPdfExport="true"
            :allowExcelExport="true"
            :allowTextWrap="true"
            :allowResizing="false"
            :groupSettings="groupOptions"
            :toolbar="toolbarOptions"
            :toolbarClick="gridExport"
            :excelExportComplete="excelExportComplete"
            :pdfExportComplete="pdfExportComplete"
        >
            <e-columns>
                <e-column
                    field="title"
                    headerText="Title"
                    textAlign="center"
                    width="120"
                ></e-column>
                <e-column
                    field="kode"
                    headerText="Kode"
                    textAlign="center"
                    width="120"
                ></e-column>
                <e-column
                    field="nm_rek"
                    headerText="Nama Rekening"
                    textAlign="left"
                    width="200"
                ></e-column>
                <e-column
                    field="formatSaldo"
                    headerText="Saldo"
                    textAlign="right"
                    width="120"
                ></e-column>
                <e-column
                    field="saldo"
                    headerText="Saldo"
                    textAlign="right"
                    format="C2"
                    width="120"
                ></e-column>
            </e-columns>
            <e-aggregates>
                <e-aggregate>
                    <e-columns>
                        <!-- utk sum all -->
                        <!-- <e-column
                            type="Sum"
                            field="saldo"
                            :footerTemplate="footerSum"
                        ></e-column> -->
                        <!-- end sum all -->

                        <!-- sum per group -->
                        <e-column
                            type="Sum"
                            field="saldo"
                            format="N2"
                            :groupFooterTemplate="groupFooterSum"
                        ></e-column>
                        <!-- end sum per group -->
                    </e-columns>
                </e-aggregate>
            </e-aggregates>
        </ejs-grid>
        <!-- end table -->
    </v-container>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import {
    GridPlugin,
    Group,
    Aggregate,
    Toolbar,
    PdfExport,
    ExcelExport,
} from "@syncfusion/ej2-vue-grids";

Vue.use(GridPlugin);

export default {
    data() {
        return {
            titleNavbar: this.$emit("titleNavbar", "Laporan Neraca"),
            idperus: JSON.parse(localStorage.getItem("localDept")).idPerus,
            nmperus: JSON.parse(localStorage.getItem("localDept")).nmPerus,
            userid: JSON.parse(localStorage.getItem("user")).id,
            search: "",
            dataJurnal: [],
            date: new Date().toISOString().substr(0, 10),
            date2: new Date().toISOString().substr(0, 10),
            menu: false,
            menu2: false,
            isLogging: false,

            groupOptions: {
                columns: ["title"],
                showDropArea: false,
            },
            toolbarOptions: ["ExcelExport"],
            // footerSum: function () {
            //     return {
            //         template: Vue.component("Sum", {
            //             template: `<span> {{data.Sum}} </span>`,
            //             data() {
            //                 return {
            //                     data: {},
            //                 };
            //             },
            //         }),
            //     };
            // },
            groupFooterSum: function () {
                return {
                    template: Vue.component("Sum", {
                        template: `<span> {{data.Sum}} </span>`,
                        data() {
                            return {
                                data: {},
                            };
                        },
                    }),
                };
            },
        };
    },

    methods: {
        loadJurnal: function () {
            this.isLogging = true;
            let req = {
                id_perus: this.idperus,
                dari_tgl: this.date,
                sampai_tgl: this.date2,
            };
            let mytoken = localStorage.getItem("token");
            const headers = {
                // "x-csrf-token": $('meta[name="csrf-token"]').attr("content"),
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };
            axios
                .post("/api/jurnal/neraca", req, {
                    headers,
                })
                .then((res) => {
                    this.dataJurnal = [];
                    for (var i in res.data.aktivalancar)
                        this.dataJurnal.push(res.data.aktivalancar[i]);
                    for (var i in res.data.aktivatetap)
                        this.dataJurnal.push(res.data.aktivatetap[i]);
                    for (var i in res.data.aktivalain)
                        this.dataJurnal.push(res.data.aktivalain[i]);
                    for (var i in res.data.hutanglancar)
                        this.dataJurnal.push(res.data.hutanglancar[i]);
                    for (var i in res.data.hutangjp)
                        this.dataJurnal.push(res.data.hutangjp[i]);
                    for (var i in res.data.modal)
                        this.dataJurnal.push(res.data.modal[i]);
                    for (var i in res.data.labaditahan)
                        this.dataJurnal.push(res.data.labaditahan[i]);
                    for (var i in res.data.lababerjalan)
                        this.dataJurnal.push(res.data.lababerjalan[i]);

                    console.log(res.data.aktivalain);

                    this.isLogging = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        gridExport(args) {
            var gridInst = this.$refs.grid;
            if (gridInst) {
                if (args.item.id.includes("pdfexport")) {
                    // gridInst.getColumns()[0].visible = false;
                    gridInst.showSpinner();
                    gridInst.pdfExport({
                        fileName: "neraca.pdf",
                        // pageOrientation: "Landscape",
                        pageSize: "Letter",
                        theme: {
                            record: {
                                fontSize: 9,
                            },
                        },
                        header: {
                            fromTop: 0,
                            height: 130,
                            contents: [
                                {
                                    type: "Text",
                                    value: this.nmperus,
                                    position: { x: 0, y: 0 },
                                    style: { fontSize: 20 },
                                },
                                {
                                    type: "Text",
                                    value: "Laporan Neraca",
                                    position: { x: 0, y: 50 },
                                    style: { fontSize: 16 },
                                },
                                {
                                    type: "Text",
                                    value:
                                        "Tanggal " +
                                        this.date +
                                        " s/d " +
                                        this.date2,
                                    position: { x: 0, y: 70 },
                                    style: { fontSize: 16 },
                                },
                            ],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents: [
                                {
                                    type: "Text",
                                    value: "Footer",
                                    position: { x: 0, y: 0 },
                                    style: { fontSize: 20 },
                                },
                                {
                                    type: "PageNumber",
                                    pageNumberType: "Number",
                                    format: "Page {$current} of {$total}", //optional
                                    position: { x: 250, y: 0 },
                                    style: {
                                        // textBrushColor: "#ffff80",
                                        fontSize: 10,
                                        hAlign: "center",
                                        vAlign: "Right",
                                    },
                                },
                            ],
                        },
                    });
                } else if (args.item.id.includes("excelexport")) {
                    gridInst.showSpinner();
                    gridInst.excelExport({
                        fileName: "neraca.xlsx",
                        theme: {
                            record: {
                                fontSize: 11,
                            },
                        },
                        header: {
                            headerRows: 5,
                            rows: [
                                {
                                    cells: [
                                        {
                                            colSpan: 4,
                                            value: this.nmperus,
                                            style: {
                                                fontSize: 14,
                                                // hAlign: "Center",
                                                bold: true,
                                            },
                                        },
                                    ],
                                },
                                { cells: [{ value: "" }] },
                                {
                                    cells: [
                                        {
                                            colSpan: 4,
                                            value: "Laporan Neraca",
                                            style: {
                                                fontSize: 12,
                                                hAlign: "Right",
                                                // bold: true,
                                            },
                                        },
                                    ],
                                },
                                {
                                    cells: [
                                        {
                                            colSpan: 4,
                                            value:
                                                "Tanggal " +
                                                this.date +
                                                " s/d " +
                                                this.date2,
                                            style: {
                                                fontSize: 12,
                                                hAlign: "Right",
                                                // bold: true,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        footer: {
                            //     footerRows: 1,
                            //     rows: [
                            //         {
                            //             cells: [
                            //                 {
                            //                     colSpan: 4,
                            //                     value: "footer",
                            //                     style: {
                            //                         fontSize: 20,
                            //                         hAlign: "Center",
                            //                         bold: true,
                            //                     },
                            //                 },
                            //             ],
                            //         },
                            //     ],
                        },
                    });
                }
            }
        },

        pdfExportComplete(args) {
            this.$refs.grid.hideSpinner();
        },

        excelExportComplete(args) {
            this.$refs.grid.hideSpinner();
        },
    },

    provide: {
        grid: [Group, Aggregate, Toolbar, PdfExport, ExcelExport],
    },
};
</script>

