<template>
    <v-container>
        <span>jurnal bank</span>
    </v-container>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>