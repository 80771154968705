<template>
    <v-container class="about pa-5">
        <h1>This is an about page</h1>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            titleNavbar: this.$emit("titleNavbar", "About"),
        };
    },
};
</script>