<template>
    <v-container>
        <!-- input -->
        <v-row class="my-1">
            <v-col cols="12" md="4" class="py-0 my-0">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            class="my-text-style"
                            v-model="date"
                            label="dari tanggal"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(date)"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="4" class="py-0 my-0">
                <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="date2"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            class="my-text-style"
                            v-model="date2"
                            label="sampai tanggal"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="date2" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu2 = false">
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menu2.save(date2)"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="4" class="py-0 my-0">
                <!-- tombol proses -->
                <v-btn
                    elevation="0"
                    class="mb-5"
                    :disabled="isLogging"
                    :loading="isLogging"
                    @click="loadrugilaba"
                    >{{ isLogging ? "Logging..." : "Proses" }}</v-btn
                >
                <!-- end tombol proses -->
            </v-col>
        </v-row>
        <!-- end input -->

        <v-row>
            <!-- table rugilaba -->
            <v-col cols="12">
                <ejs-grid
                    ref="grid2"
                    height="300"
                    :dataSource="rugilaba"
                    :allowFilter="true"
                    :allowGrouping="true"
                    :allowExcelExport="true"
                    :toolbar="toolbarOptions"
                    :toolbarClick="gridExport"
                    :groupSettings="groupOptions"
                >
                    <e-columns>
                        <e-column
                            field="title"
                            headerText="Title"
                            textAlign="center"
                            width="120"
                        ></e-column>
                        <e-column
                            field="kode"
                            headerText="Kode"
                            textAlign="center"
                            width="120"
                        ></e-column>
                        <e-column
                            field="nm_rek"
                            headerText="Nama Rekening"
                            textAlign="left"
                            width="250"
                        ></e-column>
                        <e-column
                            field="saldo"
                            headerText="Saldo"
                            textAlign="right"
                            width="150"
                        ></e-column>
                    </e-columns>
                    <e-aggregates>
                        <e-aggregate>
                            <e-columns>
                                <!-- utk sum footer -->
                                <e-column
                                    type="Sum"
                                    field="saldo"
                                    :footerTemplate="footerSum"
                                ></e-column>
                                <!-- end sum footer -->

                                <!-- sum per group -->
                                <e-column
                                    type="Sum"
                                    field="saldo"
                                    :groupFooterTemplate="groupFooterSum"
                                ></e-column>
                                <!-- end sum per group -->
                            </e-columns>
                        </e-aggregate>
                    </e-aggregates>
                </ejs-grid>
            </v-col>
            <!-- end rugilaba -->
        </v-row>
    </v-container>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import {
    GridPlugin,
    Group,
    Aggregate,
    Toolbar,
    PdfExport,
    ExcelExport,
} from "@syncfusion/ej2-vue-grids";

Vue.use(GridPlugin);

export default {
    data() {
        return {
            titleNavbar: this.$emit("titleNavbar", "Laporan Rugi Laba"),
            idperus: JSON.parse(localStorage.getItem("localDept")).idPerus,
            nmperus: JSON.parse(localStorage.getItem("localDept")).nmPerus,
            userid: JSON.parse(localStorage.getItem("user")).id,
            search: "",
            date: new Date().toISOString().substr(0, 10),
            date2: new Date().toISOString().substr(0, 10),
            menu: false,
            menu2: false,
            isLogging: false,
            rugilaba: [],
            toolbarOptions: ["ExcelExport"],
            groupOptions: {
                columns: ["title"],
                showDropArea: false,
            },
            footerSum: function () {
                return {
                    template: Vue.component("Sum", {
                        template: `<span>Total : {{data.Sum}} </span>`,
                        data() {
                            return {
                                data: {},
                            };
                        },
                    }),
                };
            },
            groupFooterSum: function () {
                return {
                    template: Vue.component("Sum", {
                        template: `<span> {{data.Sum}} </span>`,
                        data() {
                            return {
                                data: {},
                            };
                        },
                    }),
                };
            },
        };
    },

    methods: {
        loadrugilaba: function () {
            this.isLogging = true;
            let mytoken = localStorage.getItem("token");
            const headers = {
                // "x-csrf-token": $('meta[name="csrf-token"]').attr("content"),
                Authorization: "Bearer " + mytoken,
                Accept: "application/json",
            };

            let req = {
                id_perus: this.idperus,
                dari_tgl: this.date,
                sampai_tgl: this.date2,
            };

            axios
                .post("/api/jurnal/rugilaba", req, { headers })
                .then((res) => {
                    this.rugilaba = res.data.data;
                    // console.log(this.rugilaba);
                    this.isLogging = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        gridExport(args) {
            var gridInst = this.$refs.grid2;
            if (gridInst) {
                if (args.item.id.includes("pdfexport")) {
                    // gridInst.getColumns()[0].visible = false;
                    gridInst.showSpinner();
                    gridInst.pdfExport({
                        fileName: "jurnal-transaksi.pdf",
                        pageOrientation: "Landscape",
                        pageSize: "Letter",
                        theme: {
                            record: {
                                fontSize: 9,
                            },
                        },
                        header: {
                            fromTop: 0,
                            height: 130,
                            contents: [
                                {
                                    type: "Text",
                                    value: this.nmperus,
                                    position: { x: 0, y: 0 },
                                    style: { fontSize: 20 },
                                },
                                {
                                    type: "Text",
                                    value: "Laporan Jurnal Transaksi",
                                    position: { x: 0, y: 50 },
                                    style: { fontSize: 16 },
                                },
                                {
                                    type: "Text",
                                    value:
                                        "Tanggal " +
                                        this.date +
                                        " s/d " +
                                        this.date2,
                                    position: { x: 0, y: 70 },
                                    style: { fontSize: 16 },
                                },
                            ],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents: [
                                {
                                    type: "Text",
                                    value: "Footer",
                                    position: { x: 0, y: 0 },
                                    style: { fontSize: 20 },
                                },
                                {
                                    type: "PageNumber",
                                    pageNumberType: "Number",
                                    format: "Page {$current} of {$total}", //optional
                                    position: { x: 250, y: 0 },
                                    style: {
                                        // textBrushColor: "#ffff80",
                                        fontSize: 10,
                                        hAlign: "center",
                                        vAlign: "Right",
                                    },
                                },
                            ],
                        },
                    });
                } else if (args.item.id.includes("excelexport")) {
                    console.log("export excel di klik");
                    // gridInst.showSpinner();
                    gridInst.excelExport({
                        fileName: "buku besar.xlsx",
                        theme: {
                            record: {
                                fontSize: 12,
                            },
                        },
                        header: {
                            headerRows: 5,
                            rows: [
                                {
                                    cells: [
                                        {
                                            colSpan: 7,
                                            value: this.nmperus,
                                            style: {
                                                fontSize: 14,
                                                // hAlign: "Center",
                                                bold: true,
                                            },
                                        },
                                    ],
                                },
                                { cells: [{ value: "" }] },
                                {
                                    cells: [
                                        {
                                            colSpan: 7,
                                            value: "Laporan Buku Besar",
                                            style: {
                                                fontSize: 12,
                                                hAlign: "Right",
                                                // bold: true,
                                            },
                                        },
                                    ],
                                },
                                {
                                    cells: [
                                        {
                                            colSpan: 7,
                                            value:
                                                "Tanggal " +
                                                this.date +
                                                " s/d " +
                                                this.date2,
                                            style: {
                                                fontSize: 12,
                                                hAlign: "Right",
                                                // bold: true,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    });
                }
            }
        },
    },

    provide: {
        grid: [Group, Aggregate, Toolbar, PdfExport, ExcelExport],
    },
};
</script>
