<template>
    <v-container>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="6" md="6" lg="6">
                        <div class="mt-2">Tanggal Transaksi</div>
                    </v-col>
                    <v-col cols="6" md="6" lg="6" class="text-right">
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    rounded
                                    color="primary"
                                    v-model="date"
                                    v-bind="attrs"
                                    v-on="on"
                                    >{{ date }}</v-btn
                                >
                            </template>
                            <v-date-picker v-model="date" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="modal = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="
                                        $refs.dialog.save(date);
                                        handleDate(date);
                                    "
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            titleNavbar: this.$emit("titleNavbar", "Setting"),
            date:
                localStorage.getItem("tanggal") ||
                new Date().toISOString().substr(0, 10),
            modal: false,
        };
    },

    methods: {
        // currentDate() {
        //     const current = new Date();
        //     const date = `${current.getDate()}/${
        //         current.getMonth() + 1
        //     }/${current.getFullYear()}`;
        //     return date;
        // },

        handleDate(tgl) {
            localStorage.setItem("tanggal", tgl);
        },
    },
};
</script>
