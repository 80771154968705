<template>
    <el-container> Register </el-container>
</template>

<script>
export default {
    data() {
        return {};
    },
    mounted() {
        console.log(localStorage.isLogged);
    },
};
</script>